import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'
import APIReq from '../../utility/api'
import store from '../../store'
import { RESEARCH_COLUMN_HEADERS, API, LOCATIONS, ALLOWED_QUERY_OPERATOR, PUBLISHED,TAXONOMIES, FEATURED_OPTIONS, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { getTaxonomyData } from '../../utility/commonApis'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Researches extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: RESEARCH_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: ActionRenderer,
            },
            defaultColDef: { suppressMovable: true },

            name: '',
            showname: false,
            showcategory: false,
            showindustry: false,
            showis_published: false,
            category: '',
            is_featured: '',
            categorys: [],
            categoryStore: [],
            industry: "",
            industrys: [], // industries can be used due to handleInputChange code depending on ${str}s
            industryStore: [],
            is_published: '',
            filters: {
                name: [],
                category: [],
                industry: [],
                is_published: [],
                is_featured: []
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'category', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': "industry", 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [],},
                { 'key': 'is_published', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'is_featured', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showcategory: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showis_published: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showname: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry: false }) }
        })
        let paramFields = ['id', 'name', 'category','category_data', 'image_id', 'image_data','price',"industry_data", 'is_published', 'published_at']
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('research', store.fields)

        fetch(`${API}/industry?fields=id^,name`, {
            method: "GET",
            headers: {
                Authorization: store.token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    let arr = [];
                    data.data.map((item) => {
                        arr.push({ value: item.id, label: item.name });
                    });
                    this.setState({ industrys: arr, industryStore: arr });
                }
            });

        getTaxonomyData(TAXONOMIES.researchCategory).then(res => {
            this.setState({ categorys: res, categoryStore: res })
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.category) { if (!this.category.contains(e.target)) this.setState({ showcategory: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showis_published: false }) }
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showname: false }) }
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry: false }) }
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>RESEARCH</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showname: true })}
                                                onKeyUp={this.handleNameEnter}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.showname && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.category = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By category "
                                                        value={this.state.category}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'category')}
                                                        onFocus={() => { this.setState({ showcategory: true }) }} />
                                                    {this.state.showcategory && this.state.categorys ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.category.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.category.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'category')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container' style={{maxHeight: 150}}>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.categorys.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'category')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' style={{maxHeight: 150}}>
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('category')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showcategory: false }); this.handleSearchFilters('research') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showcategory ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showcategory: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showcategory: true })} ></i>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.status = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By status "
                                                        value={this.state.is_published}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'is_published')}
                                                        onFocus={() => { this.setState({ showis_published: true }) }} />
                                                    {this.state.showis_published && PUBLISHED ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.is_published.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.is_published.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'is_published')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container' style={{maxHeight: 100}}>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {PUBLISHED.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'is_published')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' style={{maxHeight: 100}} >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('is_published')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showis_published: false }); this.handleSearchFilters('research') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showis_published ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showis_published: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showis_published: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }} >
                                            <div ref={(node) => (this.industry = node)} className="filter-firstline" >
                                                <div style={{ width: "100%" }}>
                                                    <input
                                                        className="filter-input"
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Industry"
                                                        value={this.state.industry}
                                                        onChange={(e) =>
                                                            this.handleInputChange(e.target.value, "industry")
                                                        }
                                                        onFocus={() => {
                                                            this.setState({
                                                                showcategory: false,
                                                                showname: false,
                                                                showindustry: true,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.showindustry && this.state.industrys ? (
                                                        <div className="filter-dropdown">
                                                            <div>
                                                                {this.state.filters.industry.length > 0 ? (
                                                                    <div className="filter-dropdown-wrapper">
                                                                        {this.state.filters.industry.map(
                                                                            (item, i) => {
                                                                                return (
                                                                                    <div key={i} className="filter-keyword" onClick={() => this.handleRemoveFilter(i, "industry")} >
                                                                                        {item}&nbsp;
                                                                                        <i className="fa fa-times-circle"></i>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <ul className="filter-list-container">
                                                                <div className="filter-list-wrapper">
                                                                    <div>
                                                                        {this.state.industrys.map((item, j) => (
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12 }} onClick={() => this.handleSelectFilter(item, "industry")}>
                                                                                    {item.label}
                                                                                </li>
                                                                                <br />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="filter-stickybtn ">
                                                                        <div style={{ width: 140 }}>
                                                                            <a
                                                                                tabIndex="0"
                                                                                className="filter-canclebtn"
                                                                                onClick={() =>
                                                                                    this.handleClearFilter("industry")
                                                                                }
                                                                            >
                                                                Clear
                                                                            </a>{" "}
                                                            &nbsp; | &nbsp;
                                                                            <a
                                                                                tabIndex="0"
                                                                                className="filter-applybtn"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        showindustry: false,
                                                                                    });
                                                                                    this.handleSearchFilters("research");
                                                                                }}
                                                                            >
                                                                Apply
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {this.state.showindustry ? (
                                                    <i
                                                        className="fa fa-chevron-up "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() =>
                                                            this.setState({ showindustry: false })
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-chevron-down "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() =>
                                                            this.setState({ showindustry: true })
                                                        }
                                                    ></i>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                            <div className="filter-firstline">
                                                <div style={{ width: "100%" }}>
                                                    <Select
                                                        placeholder='Filter by featured'
                                                        styles={SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        value={this.state.is_featured}
                                                        onChange={(option) => this.handleSingleSelectFilter(option, 'is_featured')}
                                                        options={FEATURED_OPTIONS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleClearAllFilters('research')}>Clear All</a>
                                        {/*<a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleSearchFilters('research')}>Apply</a>*/}
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(Researches)
