import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, DELETE_OPTIONS, OPTIONS } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';
import { CUSTOM_DASHBOARD_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import organizationApi from '../../apis/organizationApi';
import customDashboardApi from '../../apis/customDashboardApi';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class CustomDashboard extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: CUSTOM_DASHBOARD_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            //filter values
            id: '',
            organization_id: '',
            status: '',
            is_deleted: '',

            // filter options
            organizations: [],
            customDashboards: [],

            filters: {
                id: [],
                organization_id: [],
                status: [],
                is_deleted: [],
            },
            filterFields: [
                { key: 'organization_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'is_deleted', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'status', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },

            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {

        // custom dashboard fields
        const paramFields = [
            'id',
            'name',
            'organization_data',
            'organization_id',
            'description',
            'tableau_workbook_name',
            'tableau_view_name',
            'tableau_site_id',
            'iframe_width',
            'iframe_height',
            'status'
        ];

        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.customDashboard, store.fields);
    }

    clearFilters = () => {
        this.setState({
            id: '',
            organization_id: '',
            status: '',
            is_deleted: '',
        }, () => this.handleClearAllFilters(API_PATH.customDashboard));
    };

    getOptionsData = async (filterOptionName, onFocus, option = "", callback) => {

        if (option && (option.length < 2 && !onFocus)) {
            return;
        }

        let responseData = []

        if (filterOptionName === "organizations") {
            responseData = await organizationApi.getOrganizations(option);
        } else {
            responseData = await customDashboardApi.getCustomDashboards(option);
        }

        const data = responseData.map((item) => {
            return {
                value: item.id,
                label: `${item.name} [${item.id}]`,
            }
        })

        this.setState({ [filterOptionName]: data })

        if (callback) {
            callback(data);
        }

    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>CUSTOM DASHBOARD</h3>
                            <div className='filter-container'>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='id'
                                                    placeholder='Filter by Custom Dashboard Name'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.id}
                                                    defaultOptions={this.state.customDashboards}
                                                    loadOptions={(value, callback) => this.getOptionsData("customDashboards", false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'id')}
                                                    onFocus={() => { this.getOptionsData("customDashboards", true) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='organization_id'
                                                    placeholder='Filter by Organization Name'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.organization_id}
                                                    defaultOptions={this.state.organizations}
                                                    loadOptions={(value, callback) => this.getOptionsData("organizations", false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'organization_id')}
                                                    onFocus={() => { this.getOptionsData("organizations", true) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by deleted status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.is_deleted}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'is_deleted')}
                                                    options={DELETE_OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by active status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.status}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                                    options={OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(CustomDashboard);
