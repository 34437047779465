import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Select from 'react-select'
import store from '../../store'
import { API, SELECT_STYLES, SELECT_THEME_PRIMARY, ALLOWED_QUERY_OPERATOR, GENERAL_SHORT_ERROR_MSG } from '../../constant'
import { getAPI } from '../../utility/commonApis'
import { get, toJS } from 'mobx'


class InviteUserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            teamRoles: [],
            formFields: {
                user_id: '',
                role: '',
                team_role: '',
                email: '',
                first_name: '',
                last_name: ''
            }
        }
    }

    componentDidMount() {
        this.getRoles();
        this.getTeamRoles();
    }

    getSnapshotBeforeUpdate(prevProps) {
        // Track down when the edit popou opens
        // So that data can be set
        if (store.isTeamEdit && store.isInviteEdit) {
            store.isInviteEdit = false;
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot) {
            const teamDetails = toJS(store.teamDetails);
        
            teamDetails.team_role = teamDetails.team_role_id;
            const formFields = { ...this.state.formFields, ...teamDetails };

            this.setState({ formFields });
        }
    }

    getRoles = async () => {
        const response = await getAPI('role', ['id', 'name']);
        const finalArr = [];

        response.map(item => {
            finalArr.push({ value: item.id, label: item.name })
        });

        this.setState({
            roles: finalArr
        });
    }

    getTeamRoles = async () => {
        const response = await getAPI('team-role', ['id', 'name']);
        const finalArr = [];

        response.map(item => {
            finalArr.push({ value: item.id, label: item.name })
        });

        this.setState({
            teamRoles: finalArr
        });
    }

    resetFormFields = () => {
        const formFields = {
            user_id: '',
            role: '',
            team_role: '',
            email: '',
            first_name: '',
            last_name: ''
        }

        this.setState({ formFields, isInviting: false });
    }

    handleInvite = async () => {
        const { formFields, isInviting } = this.state;

        if (isInviting) return;

        if (
            !formFields.first_name ||
            !formFields.last_name ||
            !formFields.email ||
            !formFields.team_role
        ) {
            return this.setState({
                errorMsg: "Please fill all the required fields"
            });
        }

        this.setState({ isInviting: true });

        const response = await getAPI('user', ['first_name', 'last_name'], [{ 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [formFields.email] }]);

        if (response && response.length > 0) {
            return this.setState({
                errorMsg: "User with this email already exist",
                isInviting: false,
            });
        }

        this.setState({ errorMsg: "" });
        
        if (this.props.isEdit) {
            await this.callInviteAPI();
        } else {
            this.pushInviteAPI();
        }
    }

    pushInviteAPI = () => {
        const { email, first_name, last_name, team_role }   = this.state.formFields;
        const { entityType }                                = this.props;
        const apiObj = {
            email,
            first_name,
            last_name,
            team_role_id: team_role.value,
            entity_type: entityType,
            entity_id: null
        };
        const teamDataObj = {
            isInvite: true,
            email: email,
            first_name: first_name,
            last_name: last_name,
            user_id: `${first_name} ${last_name}`,
            team_role_id: team_role.label,
            trid: team_role.value,
        };

        if (store.isTeamEdit) {
            const userInvitations   = get(store, 'userInvitations');
            const teamData          = get(store, 'teamData');
            const apiIndex             = userInvitations.findIndex(item => item.email === email);
            const teamDataIndex        = teamData.findIndex(item => item.email ===  email);

            store.userInvitations[apiIndex] = apiObj;
            store.teamData[teamDataIndex]   = teamDataObj;
        } else {
            store.teamData.push(teamDataObj);
            store.userInvitations.push(apiObj);
        }

        this.props.gridApi.setGridOption('rowData',store.teamData)
        this.resetFormFields();
        
        $('#inviteUserModal').modal('hide');
    }

    callInviteAPI = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { email, first_name, last_name, team_role }   = this.state.formFields;
                const { entityType }                                = this.props;
                const entityId                                      = store.currentId;

                const body = {
                    email,
                    first_name,
                    last_name,
                    team_role_id: team_role.value,
                    entity_type: entityType,
                    entity_id: entityId
                };

                const config = {
                    method: 'POST',
                    headers: {
                        'Authorization': store.token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                };

                let response = await fetch(`${API}/user/invite`, config);
                response = await response.json();
                this.setState({ isInviting: false });
                
                if (response.status == 201 && response.data) {
                    
                    store.teamData.push({
                        isInvite: true,
                        uid: response.data.id,
                        id: response.data.team_id,
                        email: email,
                        first_name: first_name,
                        last_name: last_name,
                        user_id: `${first_name} ${last_name}`,
                        team_role_id: team_role.label,
                        trid: team_role.value,
                        role_id: '-'
                    });
                    this.props.gridApi.setGridOption('rowData',store.teamData)

                    this.resetFormFields();
                    $('#inviteUserModal').modal('hide');
                    resolve(response);
                } else {
                    this.handleError(response);
                }

            } catch (error) {
                this.handleError(error.response);
                reject(error);
            }
        });
    }

    handleError = (error = null) => {
        const stateObj = {};

        if (error &&  error.errors && error.errors.length > 0 && error.errors[0].message) {
            stateObj['errorMsg'] = error.errors[0].message;
        } else {
            stateObj['errorMsg'] = GENERAL_SHORT_ERROR_MSG;
        }

        stateObj['isInviting'] = false;
        this.setState(stateObj);
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const { formFields } = this.state;

        formFields[name] = value;
        this.setState({ formFields});
    }

    handleOptionChange(option, name) {
        const { formFields } = this.state;

        formFields[name] = option;
        this.setState({ formFields});
    }

    render() {
        // @todo: It's a temp fix to the edit state issue
        // this.state.formFields   = { ...this.state.formFields, ...store.teamDetails }
        const { errorMsg }      = this.state;

        return (
            <div className="modal fade" id="inviteUserModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Invite User</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.resetFormFields}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {errorMsg &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-danger" role="alert">
                                            {errorMsg}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Email <span className='required'>*</span></label>
                                    <input
                                        disabled={(store.isTeamEdit)}
                                        type="text"
                                        className="form-control"
                                        value={this.state.formFields.email}
                                        name="email"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-6 col-sm-6 col-12 form-group">
                                    <label >First name <span className='required'>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        value={this.state.formFields.first_name}
                                        name="first_name"
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                    <label>Last name <span className='required'>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={this.state.formFields.last_name}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Team member's role <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Please Select'
                                        styles={SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.team_role}
                                        onChange={(option) => this.handleOptionChange(option, 'team_role')}
                                        options={this.state.teamRoles}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleInvite}>
                                {this.state.isInviting && <i className="fas fa-circle-notch fa-spin" style={{ marginRight: 3 }}></i>}
                                Invite
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(InviteUserModal)