import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'
// constants
import { SELECT_STYLES_ERROR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../../constant';
//api
import ecosystemApi from '../../../apis/ecosystemApi';
import { isObjectEmpty } from '../../../utility/helpers';

class AddDefaultFilterModel extends Component {
    constructor(props) {
        super(props);
        this.state = {

            hq_id: '',
            primary_industry: '',
            entity_relation: {},
            has_directory_tags: {},

            arrSelectedhq_id: [],
            arrSelectedprimary_industry: [],

            hq_idEmpty: false,
            primary_industryEmpty: false,
            entity_relationEmpty: false,
            has_directory_tagsEmpty: false,

            ecosystemEntities: [],
        }
    }

    componentDidMount() {
        const { defaultFilter, isEdit } = this.props;
        if (defaultFilter && isEdit) {
            this.setState({
                hq_id: defaultFilter?.hq_id || [],
                arrSelectedhq_id: defaultFilter?.hq_id || [],
                primary_industry: defaultFilter?.primary_industry || [],
                arrSelectedprimary_industry: defaultFilter?.primary_industry || [],
                entity_relation: defaultFilter.entity_relation,
                has_directory_tags: defaultFilter.has_directory_tags,
            });
        }
    }


    handleOptionChange = (option = {}, strLabel = '', isMulti = false) => {
        let arrSelected = []
        let tempOption = option

        if (isMulti) {
            arrSelected = [...this.state[`arrSelected${strLabel}`], tempOption]
            tempOption = ''
        }

        this.setState({
            [strLabel]: tempOption,
            [`arrSelected${strLabel}`]: arrSelected,
            [`${strLabel}Empty`]: false,
        });
    }

    handleCloseModal = () => {
        this.handleClearFields();

        this.props.setParentState({
            showDefaultFilterModal: false
        });
    }

    handleClearFields = () => {
        this.setState({
            hq_id: '',
            primary_industry: '',
            entity_relation: '',
            has_directory_tags: '',

            arrSelectedhq_id: [],
            arrSelectedprimary_industry: [],

            hq_idEmpty: false,
            primary_industryEmpty: false,
            entity_relationEmpty: false,
            has_directory_tagsEmpty: false,
        });

        $('#defaultFilterModal').modal('hide');
    }

    handleValidation = () => {
        const { entity_relation, has_directory_tags } = this.state;
        let entity_relationEmpty = false;
        let has_directory_tagsEmpty = false;

        if (isObjectEmpty(entity_relation) && isObjectEmpty(has_directory_tags)) {
            entity_relationEmpty = true;
            has_directory_tagsEmpty = true;
        }

        this.setState({
            entity_relationEmpty,
            has_directory_tagsEmpty
        });

        return  entity_relationEmpty || has_directory_tagsEmpty;
    }

    handleAddDefaultFilter = () => {
        const { arrSelectedprimary_industry, arrSelectedhq_id, entity_relation, has_directory_tags } = this.state;

        if (this.handleValidation()) {
            return;
        }

        const hqIds = arrSelectedhq_id.map(item => item.value);
        const primaryIndustries = arrSelectedprimary_industry.map(item => item.value);
        const entityRelation = { id: entity_relation.value, type: entity_relation.type };

        const obj = {
            filters: {
                hq_id: hqIds,
                primary_industry: primaryIndustries,

            },
            entity_relation: entityRelation,
            has_directory_tags: has_directory_tags.value
        }

        const option = { value: obj, label: 'default_filter' };

        this.props.handleAddDefaultFilter(option, 'default_filter');
        this.handleClearFields();
    }

    getEcosystemEntities = async (onFocus = false, option = '', callback) => {

        if (option && option.length < 2 && !onFocus) {
            return;
        }

        let arrEcosystemEntities = []

        await ecosystemApi.getEcosystems(option).then(
            response => {
                arrEcosystemEntities = response.map(item => {
                    return { value: item.id, label: item.name, type: item.ecosystem_type };
                });

                this.setState({ ecosystemEntities: arrEcosystemEntities });
            }
        );

        if (callback) {
            callback(arrEcosystemEntities);
        }
    }

    handleRemoveOption = (option, strLabel) => {
        const arrSelected = this.state[`arrSelected${strLabel}`].filter(item => item.value !== option.value);

        this.setState({
            [`arrSelected${strLabel}`]: arrSelected
        });
    }

    render() {
        return (
            <div className="modal fade" id="defaultFilterModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Default Filter</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Location </label>
                                    <Select
                                        placeholder='Select a Location'
                                        styles={this.state.hq_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.hq_id}
                                        onChange={(option) => this.handleOptionChange(option, 'hq_id', true)}
                                        options={this.props.locations}
                                    />
                                    {this.state.arrSelectedhq_id.length > 0 &&
										<div className='permission-policy-list-wrapper geo-focus-list'>
										    {this.state.arrSelectedhq_id.map((item, i) => {
										        return <li key={i + item.label}>{item.label} <i className='fa fa-times-circle' onClick={() => this.handleRemoveOption(item, 'hq_id')}></i></li>
										    })}
										</div>
                                    }

                                    {this.state.hq_idEmpty && <div className='error'>Location cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Primary Industry
                                        {/* <span className='required'>*</span> */}
                                    </label>
                                    <Select
                                        placeholder='Select a Primary industry'
                                        styles={this.state.primary_industryEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.primary_industry}
                                        onChange={(option) => this.handleOptionChange(option, 'primary_industry', true)}
                                        options={this.props.primaryIndustries}
                                    />
                                    {this.state.arrSelectedprimary_industry.length > 0 &&
										<div className='permission-policy-list-wrapper geo-focus-list'>
										    {this.state.arrSelectedprimary_industry.map((item, i) => {
										        return <li key={i + item.label}>{item.label}
										            <i
										                className='fa fa-times-circle'
										                onClick={() => this.handleRemoveOption(item, 'primary_industry')}
										            ></i>
										        </li>
										    })}
										</div>
                                    }
                                    {/* {this.state.primary_industryEmpty && <div className='error'>Primary Industry cannot be empty</div>} */}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Entity Relation <span className='required'>*</span></label>
                                    <AsyncSelect
                                        name='entity_relation'
                                        placeholder='Select Entity Relation'
                                        styles={this.state.entity_relationEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.entity_relation}
                                        defaultOptions={this.state.ecosystemEntities}
                                        loadOptions={(value, callback) => this.getEcosystemEntities(false, value, callback)}
                                        onChange={(option) => this.handleOptionChange(option, 'entity_relation')}
                                        onFocus={() => this.getEcosystemEntities(true)}
                                    />
                                    {this.state.entity_relationEmpty && <div className='error'>Entity Relation cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Directory Tag <span className='required'>*</span></label>
                                    <Select
                                        name='has_directory_tags'
                                        placeholder='Select a period'
                                        styles={this.state.has_directory_tagsEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.has_directory_tags}
                                        onChange={(option) => this.handleOptionChange(option, 'has_directory_tags')}
                                        options={[
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' },
                                        ]}
                                    />
                                    {this.state.has_directory_tagsEmpty && <div className='error'>Directory Tags cannot be empty</div>}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddDefaultFilter}>Add Default Filter</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddDefaultFilterModel;
