import { observable } from 'mobx'

let tempStore = {
    userId: '',
    isLoggedin: false,
    token: '',
    refreshToken: '',
    permissions: {},
    isAdd: false,
    isCopy: false,
    isEdit: false,
    isDeleted: false,
    isAddSubTaxonomy: false,
    showTaxonomies: false,
    isTaxonomyList: true,
    isIndustryList: true,
    industryParentId: 0,
    isSideNav: false,
    showDelete: false,
    showDeactivate: false,
    showPublish: false,
    isAppList: false,
    publish: '',
    deactivate: '',
    // type: 'startup',
    type: '',
    // route: 'startup',
    route: '',
    form: '',
    fields: '',
    taxonomyTitle: '',
    currentId: '',
    currentIndex: '',
    currentDeleteName: '',
    currentAddedName: '',
    currentEditName: '',
    currentFilters: '',
    currentSort: '',
    locations: [],
    rowData: [],
    teamData: [],
    teams: [],
    peopleTeamData: [],
    peopleTeams: [],
    mentionData: [],
    mentions: [],
    fundingData: [],
    funding: [],
    userInvitations: [],
    page: 0,
    lastPage: 0,
    totalCount: 0,
    fromCount: 0,
    toCount: 0,
    FormFieldsTaxonomy: '',
    isInvestorEdit: false,
    investorDetails: '',
    applicationId: '',
    isTeamEdit: false,
    teamDetails: '',
    teamRowIndex: '',
    isPeopleTeamEdit: false,
    peopleTeamDetails: '',
    peopleTeamRowIndex: '',
    mentionDetails: '',
    mentionRowIndex: '',
    isFundingEdit: false,
    fundingDetails: '',
    fundingRowIndex: '',
    apis: [],
    currentTeamId: '',
    currentPeopleTeamId: '',
    currentFundingId: '',
    currentMentionId: '',
    sortType: '',
    researchTitle: '',
    isInviteEdit: false,
    isGroupsEdit: false,
    rolesData: [],
    subscriptionRoles: [],
    successLabel: '',
    filterFieldsOptions: [],
    parentIndustry: null,
    parent_industries: [],
    orderType: '',
    fundingBreakdowns: [],
    fundingBreakdownsData: [],
    fundingBreakdownDetails: {},
    isFundingBreakdownEdit: false,
    currentFundingBreakdownId: '',
    fundingBreakdownRowIndex: '',
    investorFunding: [],
    investorFundingData: [],
    investorFundingDetails: {},
    investor_funding_id: '',
    funding_breakdown_id: '',
    startup_id: '',
    organizationUsers: [],
    organizationUsersData: [],
    organizationUserDetails: {},
    apiClientID: '',
    apiClientSecret: '',
    numDeactivatedUsers: '',
    subscriptionAddonRolesData: [],
    subscriptionAddonRoles: [],
    clientDirectoryTaxoRowIndex: '',
    clientDirectoryTaxoData: [],
    clientDirectoryTaxoDetails: '',
    isEditTaxos: false,
    currentTaxoId: '',
    clientDirectoryPostCreateTaxo: {}
};
let store

if (typeof localStorage['state'] != "undefined") {
    store = observable({
        ...tempStore,
        ...JSON.parse(localStorage.state)
    })
}
else {
    store = observable(tempStore)
}

export default store
