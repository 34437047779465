import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';
import { ENTITY_EMPLOYEE_COUNT_HEADERS } from '../../utility/constants/tableHeaders';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';
import { getMonthArray, getYearArray } from '../../utility/helpers';

// apis
import { getEntities, getUsers } from '../../utility/commonApis';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';


class EntityEmployeeCount extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: ENTITY_EMPLOYEE_COUNT_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            //filter values
            entity_id: '',
            entity_type: '',
            created_by: '',
            year: '',
            month: '',
            employee_count: '',
            entity_typeEmpty: false,

            // filter options
            entityData: [],
            users: [],

            showEmployeeCount: false,

            filters: {
                entity_id: [],
                entity_type: [],
                created_by: [],
                year: [],
                month: [],
                employee_count: [],
            },

            entityTypes: [
                { value: 'STARTUP', label: 'STARTUP' },
                { value: 'ENABLER', label: 'ENABLER' },
                { value: 'INVESTOR', label: 'INVESTOR' },
                { value: 'CORPORATE', label: 'CORPORATE' },
            ],

            filterFields: [
                { key: 'entity_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'entity_type', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'created_by', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'year', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'month', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'employee_count', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ],
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.employee_count) { if (!this.employee_count.contains(e.target)) this.setState({ showEmployeeCount: false }) }
        })

        // fields to be fetched from the API
        const paramFields = [
            'id',
            'entity_id',
            'entity_type',
            'entity_data',
            'month',
            'year',
            'employee_count',
            'created_at',
            'updated_at',
            'created_by',
            'created_by_data'
        ];

        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.entityEmployeeCount, store.fields);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.employee_count) { if (!this.employee_count.contains(e.target)) this.setState({ showEmployeeCount: false }) }
        })
    }

    clearFilters = () => {
        this.setState({
            entity_id: '',
            entity_type: '',
            created_by: '',
            year: '',
            month: '',
            employee_count: '',
        }, () => this.handleClearAllFilters(API_PATH.entityEmployeeCount));
    };

    getOptionsData = async (entityType, onFocus, option = "", callback) => {

        if (!entityType || (option && option.length < 2 && !onFocus)) {
            if (!entityType?.value && entityType !== 'users') {
                this.setState({
                    entity_typeEmpty: true,
                });
            }
            return;
        }

        let data = []
        if (entityType === 'users') {
            data = await getUsers(option);

            this.setState({
                users: data,
            })

        } else {
            data = await getEntities(entityType.value.toLowerCase(), option);

            this.setState({
                entityData: data,
                entity_typeEmpty: false,
            })
        }

        if (callback) {
            callback(data);
        }
    }

    render() {
        const months = getMonthArray();
        const years = getYearArray();

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>ENTITY EMPLOYEE COUNT</h3>
                            <div className='filter-container'>
                                <div className="filter-row row" style={{ paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" >
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by Month'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.month}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'month')}
                                                    options={months}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " >
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by Year'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.year}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'year')}
                                                    options={years}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row row" style={{ paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " >
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by Entity Type'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.entity_type}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'entity_type')}
                                                    options={this.state.entityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " >
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='name'
                                                    placeholder='Filter by Entity Name'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.entity_id}
                                                    defaultOptions={this.state.entityData}
                                                    loadOptions={(value, callback) => this.getOptionsData(this.state.entity_type, false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'entity_id')}
                                                    onFocus={() => { this.getOptionsData(this.state.entity_type, true) }}
                                                />
                                            </div>
                                        </div>
                                        {this.state.entity_typeEmpty && <div className='error'>Please select an entity type before choosing an entity name.</div>}
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div ref={node => this.employee_count = node} className="col-md-6 col-sm-6 col-12 p-0" >
                                        <div className='filter-firstline filter-spacing'>
                                            <div style={{ width: '100%' }}>
                                                <input ref={node => this.input = node}
                                                    className='filter-input input-border'
                                                    placeholder="Filter by Employee Count"
                                                    value={this.state.employee_count}
                                                    onFocus={() => this.setState({ showEmployeeCount: true })}
                                                    onKeyUp={event => this.handleKeywordEnter(event, 'employee_count')}
                                                    onChange={e => this.setState({ employee_count: e.target.value })}
                                                    style={{ height: 38 }}
                                                />
                                                {this.state.showEmployeeCount && this.state.filters.employee_count.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.employee_count.map((item, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'employee_count')}>
                                                                    {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            {this.state.employee_count.length > 0 &&
												<i className="fa fa-times "
												    style={{ paddingRight: 10 }}
												    onClick={() => {
												        this.input.focus()
												        this.setState({ employee_count: '' })
												    }}>
												</i>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingRight: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <AsyncSelect
                                                    name='created_by'
                                                    placeholder='Filter by Created By'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.created_by}
                                                    defaultOptions={this.state.users}
                                                    loadOptions={(value, callback) => this.getOptionsData("users", false, value, callback)}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'created_by')}
                                                    onFocus={() => { this.getOptionsData("users", true) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(EntityEmployeeCount);
