import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'

import store from '../../store'
import { JOBS_COLUMN_HEADERS, API, TAXONOMIES, ALLOWED_QUERY_OPERATOR, FEATURED_OPTIONS, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { getTaxonomyData } from '../../utility/commonApis'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Jobs extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: JOBS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            showKeywords: false,
            type_id: '',
            is_featured: '',
            type_ids: [ //TODO: when type is ready as taxonomy
                { value: 0, label: 'Full time' },
                { value: 1, label: 'Contractor' },
                { value: 2, label: 'Intern' },
                { value: 3, label: 'Co-Founder' }
            ],
            type_idStore: [ //TODO: when type is ready as taxonomy
                { value: 0, label: 'Full time' },
                { value: 1, label: 'Contractor' },
                { value: 2, label: 'Intern' },
                { value: 3, label: 'Co-Founder' }
            ],
            showtype_id: false,
            primary_role_id: '',
            primary_role_ids: [],
            primary_role_idStore: [],
            showprimary_role_id: false,
            filters: {
                keyword: [],
                type_id: [],
                primary_role_id: [],
                is_featured: []
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'type_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'primary_role_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'is_featured', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype_id: false }) }
            if (this.role) { if (!this.role.contains(e.target)) this.setState({ showprimary_role_id: false }) }
        })
        let paramFields = ['id', 'title', 'entity_id', 'entity_data','primary_role_id','primary_role_data','type_id','type_data']
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('job', store.fields)

        getTaxonomyData(TAXONOMIES.jobRole).then(res => {
            this.setState({ primary_role_ids: res, primary_role_idStore: res })
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype_id: false }) }
            if (this.role) { if (!this.role.contains(e.target)) this.setState({ showprimary_role_id: false }) }
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>JOBS</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.loc = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Type"
                                                        value={this.state.type_id}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'type_id')}
                                                        onFocus={() => { this.setState({ showDateFounded: false, showtype_id: true, showprimary_role_id: false }) }} />
                                                    {this.state.showtype_id && this.state.type_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.type_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.type_id.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'type_id')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.type_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'type_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('type_id')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showtype_id: false }); this.handleSearchFilters('job') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showtype_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype_id: true })} ></i>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.role = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder=" By Role"
                                                        value={this.state.primary_role_id}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'primary_role_id')}
                                                        onFocus={() => { this.setState({ showtype_id: false, showprimary_role_id: true }) }} />
                                                    {this.state.showprimary_role_id && this.state.primary_role_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.primary_role_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.primary_role_id.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'primary_role_id')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.primary_role_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'primary_role_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('primary_role_id')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showprimary_role_id: false }); this.handleSearchFilters('job') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showprimary_role_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showprimary_role_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showprimary_role_id: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group" style={{ paddingLeft: 0 }}>
                                            <Select
                                                placeholder='Filter by featured'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.is_featured}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'is_featured')}
                                                options={FEATURED_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleClearAllFilters('job')}>Clear All</a>
                                        {/*<a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleSearchFilters('job')}>Apply</a>*/}
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(Jobs)
