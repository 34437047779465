import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// constants
import { LOCATION_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, LOCATION_TYPES } from '../../constant';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';

// reusable components
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Pagination from '../reusable/Pagination';

class Location extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: LOCATION_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            type: '',
            name: '',
            showNames: false,
            filters: {
                name: []
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.like, 'value': [] },
                { 'key': 'is_region', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'country', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showNames: false }) }
        })

        // set store type for api usage
        store.type = 'location';

        const paramFields = ['id', 'name'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData('location', store.fields);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showNames: false }) }
        })
    }

    handleTypeFilter = (option) => {
        this.setState({ type: option });

        // region
        if (option.value === 0) {
            this.setState(prevState => ({
                filterFields: [
                    ...prevState.filterFields,
                    { 'key': 'is_region', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [1] },
                    { 'key': 'country', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] }
                ]
            }), () => {
                this.handleSearchFilters('location');
            })
        }

        // country
        else if (option.value === 1) {
            this.setState(prevState => ({
                filterFields: [
                    ...prevState.filterFields,
                    { 'key': 'is_region', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] },
                    { 'key': 'country', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] }
                ]
            }), () => {
                this.handleSearchFilters('location');
            })
        }

        // city
        else if (option.value === 2) {
            this.setState(prevState => ({
                filterFields: [
                    ...prevState.filterFields,
                    { 'key': 'is_region', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] },
                    { 'key': 'country', 'op': ALLOWED_QUERY_OPERATOR.notEq, 'value': [0] }
                ]
            }), () => {
                this.handleSearchFilters('location');
            })
        }
    }

    handleAddNameFilter = (event) => {
        const { name, filters, filterFields } = this.state;
        let tempFilters = { ...filters };
        let tempFilterFields = [...filterFields];

        if (event.keyCode === 13 && name !== '') {
            if (tempFilters.name.indexOf(name) === -1) {
                tempFilters.name.push(name)

                tempFilterFields.map(item => {
                    if (item.key === 'name') {
                        item.value.push(name)
                    }
                })
            }

            this.setState({
                name: '',
                filters: tempFilters,
                filterFields: tempFilterFields
            }, () => {
                this.handleSearchFilters('location');
            })
        }
    }

    handleRemoveNameFilter = (index) => {
        const { filters, filterFields } = this.state;
        let tempFilters = { ...filters };
        let tempFilterFields = [...filterFields];

        tempFilters.name.splice(index, 1);

        tempFilterFields.map(item => {
            if (item.key === 'name') {
                item.value.splice(index, 1)
            }
        })

        this.setState({
            filters: tempFilters,
            filterFields: tempFilterFields
        }, () => {
            this.handleSearchFilters('location');
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>
                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>LOCATION</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search by Location Name"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showNames: true })}
                                                onKeyUp={this.handleAddNameFilter}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.showNames && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveNameFilter(i)}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Select Location Type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.type}
                                            onChange={(option) => this.handleTypeFilter(option)}
                                            options={LOCATION_TYPES}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d', marginTop: 20 }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }

            </div>
        )
    }
}
export default observer(Location)
