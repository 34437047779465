export const API_PATH = {
    dataExportPolicy: 'data-export-policy',
    dataExportLog: 'data-export-log',
    dataExportProduct: 'data-export-product',
    funding: 'funding',
    fundingBreakdown: 'funding-breakdown',
    investorFunding: 'investor-funding',
    newsUTM: 'news-utm',
    fundingRequests: 'investor-funding/setting/funding-requests',
    magnittDepartment: 'magnitt-department',
    organization: 'organization',
    organizationUser: 'organization-user',
    organizationRole: 'organization-role',
    apiProduct: 'api-product',
    apiClient: 'api-clients',
    subscriptionAddon: 'subscription-addon',
    subscriptionAddonRole: 'subscription-addon-role',
    valuationCalculation: 'valuation-calculation',
    people: 'people',
    fund: 'fund',
    technology: 'technology',
    valuationCalculationLog: 'valuation-calculation-log',
    salesAutomationEmail: 'sales-automation-email',
    customDashboard: 'custom-dashboard',
    undisclosedProxyLog: 'undisclosed-proxy-log',
    clientDirectory: 'client-directory',
    entityEmployeeCount: 'entity-employee-count',
};
