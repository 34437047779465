import React, { Component } from 'react';

// helpers
import store from '../../../store';

class FundingBreakdownActionRenderer extends Component {
    handleAddInvestor = () => {
        store.currentFundingBreakdownId = this.props.data.id;
        $('#investorsEditModal').modal('show');
    }

    handleEdit = () => {
        store.isFundingBreakdownEdit    = true;
        store.currentFundingBreakdownId = this.props.data.id;
        store.fundingBreakdownRowIndex  = this.props.node.rowIndex;
        store.fundingBreakdownDetails   = store.fundingBreakdowns[this.props.node.rowIndex];

        this.props.context.componentParent.setState({ fundingError: false });

        $('#fundingBreakdownModal').modal('show');
    }

    handleDelete = () => {
        store.currentFundingBreakdownId = this.props.data.id;
        this.props.context.componentParent.handleDeleteFundingBreakdown(this.props.node.rowIndex);
    }

    render() {
        return (
            <div>

                <a tabIndex='1' className="btn-edit" onClick={this.handleAddInvestor}>Add Investor</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a tabIndex='2' className="btn-edit" onClick={this.handleEdit}><i className='fas fa-pencil-alt'></i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a tabIndex='3' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>

            </div>
        )
    }
}

export default FundingBreakdownActionRenderer

