import React, { Component } from 'react'
import store from '../../../store';

class ClientDirTaxonomyActionRenderer extends Component {
    handleEdit = () => {
        store.isEditTaxo = true;
        store.currentTaxoId = this.props.data.id;
        store.clientDirectoryTaxoRowIndex = this.props.rowIndex;

        this.props.context.componentParent.setState({ tagError: false });

        let obj = { ...this.props.data };

        store.clientDirectoryTaxoDetails = obj

        $('#addTaxoModal').modal('show')
    }

    handleDelete = () => {
        store.currentTaxoId = this.props.data.id
        this.props.context.componentParent.handleDeleteClientDirTaxo(this.props.rowIndex)
    }

    render() {
        return (
            <div>
                <a tabIndex='1' className="btn-edit" onClick={this.handleEdit}><i className='fas fa-pencil-alt'></i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default ClientDirTaxonomyActionRenderer

