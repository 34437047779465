import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import store from '../../store'
import RBAC from "../../utility/rbac"
import { EXPORT_REQUEST_STATUS_VALUES, EXPORT_REQUEST_STATUS_LABELS, DEMO_REQUEST_STATUS_LABELS, DEMO_REQUEST_STATUS_VALUES, ICE_VERIFICATION_STATUS, PARENT_INDUSTRY_ID } from '../../constant'
import { getTaxonomyData } from '../../utility/helpers'

class ActionRenderer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            action: '',
            showView: false,
            showEdit: false,
            showAdd: false,
            showCopy: false,
            showApplied: false,
            showQuestion: false,
            showSubTaxonomy: false,
            showSubIndustires: false,
            showUserSub: false
        }
    }

    getNames = (str) => {
        switch (store.type) {
        case 'user':
            store[str] = this.props.data.username
            break;
        case 'news':
            store[str] = this.props.data.title
            break;
        case 'job':
            store[str] = this.props.data.title
            break;
        case 'research':
            store[str] = this.props.data.title
            break;
        case 'application':
            store[str] = store.isAppList ? this.props.data.name : 'this Question'
            break;
        case 'funding':
            store[str] = `funding for ${this.props.data.startup}`
            break;
        case 'connection':
            store[str] = 'this Connection'
            break;
        case 'permission':
            store[str] = 'the Permission'
            break;
        case 'user-role':
            store[str] = 'the User Role'
            break;
        case 'order':
            store[str] = this.props.data.id
            break;
        case 'rd-policy':
            store[str] = 'The report download policy'
            break;
        case 'undisclosed-proxy':
            store[str] = 'The undisclosed proxy record'
            break;
        case 'data-export-product':
            store[str] = 'Data export product'
            break;
        case 'data-export-policy':
            store[str] = 'Data export policy'
            break;
        case 'promotion':
            store[str] = 'The promotion'
            break;
        default:
            store[str] = this.props.data.name
            break;
        }
    }

    handleView = () => {
        store.isEdit = false
        store.isDeleted = false
        store.isAdd = false
        store.currentId = this.props.data.id
        store.researchTitle = this.props.data.title
    }

    handleEdit = () => {
        this.getNames('currentEditName')
        store.isEdit = true
        store.isDeleted = false
        store.isAdd = false
        store.showPublish = false
        store.showDelete = false
        store.showDeactivate = false
        store.currentId = this.props.data.id
        store.currentIndex = this.props.node.rowIndex

        if (store.type === 'industry' || store.type === 'taxonomy-type' || store.type === 'research-taxonomy' || store.type === 'team-role') {
            switch (store.type) {
            case 'industry':
                store.taxonomyTitle = 'INDUSTRY'
                break;
            case 'taxonomy-type':
                store.taxonomyTitle = store.isTaxonomyList ? 'TAXONOMY' : 'SUBTAXONOMY'
                break;
            case 'research-taxonomy':
                store.taxonomyTitle = 'RESEARCH TAGS'
                break;
            case 'team-role':
                store.taxonomyTitle = 'TEAM ROLES'
                break;
            default:
                break;
            }

            getTaxonomyData();
        } else {

        }
    }

    handleCopy = () => {
        // this.getNames('currentEditName')
        store.isEdit = false
        store.isDeleted = false
        store.isAdd = true
        store.isCopy = true
        store.currentId = this.props.data.id
        store.currentIndex = this.props.node.rowIndex
    }

    handleShowModal = () => {
        store.currentId = this.props.data.id
        store.startupId = this.props.data.startup_id
        store.funding_id = this.props.data.funding_id
        store.currentIndex = this.props.node.rowIndex
        store.isAdd = false
        store.isEdit = false
        store.isDeleted = false
        store.showVerify = false

        if (this.state.action === 'deactivate') {
            store.showDeactivate = true
            store.showPublish = false
            store.showDelete = false
            store.showVerify = false
            if (store.type === 'user') {
                store.deactivate = this.props.data.status === 'Active' ? 2 : 1
            }
            else {
                store.subscriptionStatus = this.props.data.status
            }
        }
        else if (this.state.action === 'publish') {
            store.showDeactivate = false
            store.showDelete = false
            store.showPublish = true
            store.publish = this.props.data.status === 'Draft' ? 0 : this.props.data.status === 'Published' ? 1 : 2
            store.showVerify = false
        }
        else if (this.state.action == 'funding-request') {
            store.investor_funding_id = this.props.data.id;
            store.funding_breakdown_id = this.props.data.funding_breakdown_id
            store.startup_id = this.props.data.startup_id;

            store.requestor = this.props.data.requestor;
            store.startup_name = this.props.data.startup_name;
            store.stage = this.props.data.stage;
            store.showVerify = false

        }
        else if (this.state.action == 'export-request') {
            store.export_request_id = this.props.data.id;
        }
        else if (this.state.action == 'demo-request') {
            store.demo_request_id = this.props.data.id;
        }
        else if (this.state.action === 'verify_ice') {
            store.verifyData = this.props.data;
            store.showVerifyICE = true;
        }
        else if (this.state.action === 'toggle-resource') {
            store.showDeactivate = true
            store.showPublish = false
            store.showDelete = false
            store.showVerify = false

            store.resourceStatus = this.props.data.active;
        }
        else {
            store.showDelete = true
            store.showPublish = false
            store.showDeactivate = false
            store.showVerify = false
        }

        this.getNames('currentDeleteName')
        $('#dailogModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleAdd = () => {
        store.isEdit = false
        store.isDeleted = false
        store.isAdd = true
        store.currentId = this.props.data.id
        this.getNames('currentAddName')
    }

    handleisAppList = () => {
        store.currentId = this.props.data.id
        store.currentIndex = this.props.node.rowIndex
        store.isAppList = false
        store.applicationId = this.props.data.id
    }

    handleisTaxonomyList = () => {
        store.currentId = this.props.data.id
        store.currentIndex = this.props.node.rowIndex
        store.isTaxonomyList = false
        store.taxonomyId = this.props.data.id
    }

    handleSubIndustriesList = () => {
        store.currentId = this.props.data.id
        store.isIndustryList = false
        store.industryParentId = this.props.data.parent_id
    }

    handleisUserSubList = () => {
        store.currentId = this.props.data.id
        store.orderId = this.props.data.id
        store.type_id = this.props.data.type_id
        store.sub_type_id = this.props.data.sub_type_id
        store.currentIndex = this.props.node.rowIndex
        store.isOrderList = false
        if (window.location.pathname.includes('online')) {
            store.orderType = 'online'
        } else {
            store.orderType = 'offline'
        }
    }

    handleShowEndDateModal = () => {
        store.data = this.props.data;

        $('#endDateModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    render() {
        let view
        let copy
        let type = `${store.type}/edit`
        //TODO: !!!!important!!!! Refactor: Never update state in render!!!
        // Check user has edit access
        if (RBAC.hasAccess(store.type, 'patch')) {
            this.state.showEdit = true
        }

        // Check user has delete access
        if (RBAC.hasAccess(store.type, 'delete')) {
            this.state.action = 'delete'
        }

        switch (store.type) {
        case 'user':
            // Check user has edit access && Status change is differenct and active/deactive is different
            if (RBAC.hasAccess(store.type, 'patch', ':param/status-change')) {
                this.state.action = 'deactivate'
            }

            // I have no idea why this is here
            store.form = 'userform';

            break;
        case 'subscription':
            // Check user has activate/deactivate access
            if (RBAC.hasAccess(store.type, 'patch', ':param/activate')) {
                this.state.action = 'deactivate'
            }

            break;
        case 'subscription-types':
            if (RBAC.hasAccess('subscription-type', 'patch', ':param')) {
                this.state.showEdit = true
            }

            break;
        case 'news':
            // Check user has access to publish news - better to have separate api call
            if (RBAC.hasAccess(store.type, 'patch')) {
                this.state.action = 'publish'
            }

            break;
        case 'research':
            if (RBAC.hasAccess(store.type, 'patch')) {
                this.state.action = 'publish'
            }

            break;
        case 'location':
            type = 'taxonomy/location/edit';
            if (RBAC.hasAccess(store.type, 'delete')) {
                this.state.action = 'delete';
            }

            break;
        case 'funding-round-requests':
            this.state.action = 'funding-request'
            break;
        case 'industry':
            if (store.isIndustryList) {
                // If item is sub industry
                if (this.props.data.parent_id > PARENT_INDUSTRY_ID) {
                    this.state.showSubIndustires = false;
                } else {
                    this.state.showSubIndustires = true;
                }
                type = 'taxonomy/industry'
                this.state.showEdit = true;
                this.state.action = 'delete'
            } else {
                type = 'taxonomy/industry/sub-industries'
                this.state.showSubIndustires = false;
                this.state.showEdit = false;
                this.state.action = ''
            }

            break;
        case 'taxonomy-type':
            if (store.isTaxonomyList) {
                type = 'taxonomy/taxonomy-type'
                if (RBAC.hasAccess(store.type, 'get', ':param/taxonomy/:param')) {
                    this.state.showSubTaxonomy = true;
                }
                if (RBAC.hasAccess(store.type, 'delete')) {
                    this.state.action = 'delete';
                }
            }
            else {
                type = 'taxonomy/taxonomy-type/sub-taxonomy'
            }

            break;
        case 'research-taxonomy':
            type = 'taxonomy/research-taxonomy'

            break;
        case 'team-role':
            type = 'taxonomy/team-role'

            break;
        case 'application':
            if (store.isAppList) {
                if (RBAC.hasAccess(store.type, 'get', ':param/submission')) {
                    this.state.showApplied = true
                }

            }
            else {
                if (RBAC.hasAccess(store.type, 'post', ':param/question')) {
                    this.state.showAdd = true
                }

                if (RBAC.hasAccess(store.type, 'patch', ':param/question/:param')) {
                    this.state.showEdit = true
                }

                // Check user has delete access
                if (RBAC.hasAccess(store.type, 'delete', ':param/question/:param')) {
                    this.state.action = 'delete'
                }

                type = `application/question/edit`
            }

            break;
        case 'order':
            if (store.isOrderList) {
                // Forcefully disabled edit - no relation with RBAC
                this.state.showEdit = false

                if (RBAC.hasAccess(store.type, 'get', ':param/user-subscription')) {
                    this.state.showUserSub = true
                }
            }
            else {
                // Forceful disabling edit
                this.state.showEdit = false
                this.state.showUserSub = false
                if (this.props.data.status != 2 && RBAC.hasAccess(store.type, 'delete')) {
                    this.state.action = 'delete'
                }

                if (RBAC.hasAccess('user-sub', 'patch', ':param')) {
                    this.state.allowUpdate = 'update-sub-end-date';
                }
            }

            break;
        case 'permission':

            if (RBAC.hasAccess(store.type, 'post', null)) {
                this.state.showCopy = true
                copy = `${store.type}/add`
                store.form = 'permissionform' // Now I understood why we need this :D check FormParent.handleLoadOptions
            }

            break;
        case 'demo-request':
            this.state.action = 'demo-request'
            this.state.showEdit = false
            break;
        case 'export-request':
            this.state.action = 'export-request'
            this.state.showEdit = false
            break;
        case 'ecosystem':
            if (RBAC.hasAccess('investor', 'patch', ':param/verify')
                    && RBAC.hasAccess('corporate', 'patch', ':param/verify')
                    && RBAC.hasAccess('enabler', 'patch', ':param/verify')) {
                this.state.action = 'verify_ice'
            }
            break;
        case 'undisclosed-proxy':
        case 'role':
            this.state.action = '';
            break;
        case 'resource':
            if (RBAC.hasAccess('resource', 'patch', ':param')) {
                this.state.showEdit = true;
            }

            if (RBAC.hasAccess('resource', 'patch', ':param/activate') || RBAC.hasAccess('resource', 'patch', ':param/deactivate')) {
                this.state.action = 'toggle-resource';
            }
            break;
        case 'data-export-policy':
            if (RBAC.hasAccess(store.type, 'post', null)) {
                this.state.showCopy = true;
                copy = `${store.type}/add`;
            }
            break;
        case 'data-export-product':
            if (RBAC.hasAccess(store.type, 'post', null)) {
                store.form = 'dataExportProductForm';
            }
            break;
        }

        return (
            <div>
                {this.state.showView && <Link to={`/${view}`} className="btn-edit" onClick={this.handleView}>View downloads <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showAdd && <Link to={`/application/question/add`} className="btn-edit" onClick={this.handleAdd}>Add <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showCopy && <Link to={`/${copy}`} className="btn-edit" onClick={this.handleCopy}>Copy <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showEdit && <Link to={`/${type}`} className="btn-edit" onClick={this.handleEdit}>Edit <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showApplied && <Link to='/application/appliedby' className="btn-edit" onClick={this.handleisAppList} >Applied by <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showSubTaxonomy && <Link to='/taxonomy/taxonomy-type/sub-taxonomy' className="btn-edit" onClick={this.handleisTaxonomyList} >List sub taxonomies <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showSubIndustires && <Link to='/taxonomy/industry/sub-industries' className="btn-edit" onClick={this.handleSubIndustriesList} >List sub industries <span>&nbsp;|&nbsp;</span></Link>}
                {this.state.showUserSub && <Link to='/order/user-sub' className="btn-edit" onClick={this.handleisUserSubList} >Subscriptions<span>&nbsp;|&nbsp;</span></Link>}
                {this.state.allowUpdate === 'update-sub-end-date' && <a className="btn-edit" style={{color: '#034078'}} onClick={this.handleShowEndDateModal} >Update <span>&nbsp;|&nbsp;</span></a>}
                {this.state.action === 'delete' && <a tabIndex='1' className="btn-delete" onClick={this.handleShowModal} >{store.route === 'order/online' ? "Cancel" : "Delete"}</a>}
                {this.state.action === 'deactivate' && <a tabIndex='1' className="btn-delete" onClick={this.handleShowModal} >{this.props.data.status === 'Active' || this.props.data.status === 1 ? 'Deactivate' : 'Activate'}</a>}
                {this.state.action === 'publish' && <a tabIndex='1' className="btn-delete" onClick={this.handleShowModal} >{this.props.data.status === 'Draft' || this.props.data.status === 'UnPublished' ? 'Publish' : 'UnPublish'}</a>}
                {this.state.action === 'funding-request' && <a tabIndex='1' className="btn-delete" onClick={() => { store.showAcceptFundingRequest = true;  this.handleShowModal() }} >Accept</a>}
                {this.state.action === 'funding-request' && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={() => { store.showRejectFundingRequest = true; this.handleShowModal() }} >Ignore</a>}
                {this.state.action === 'demo-request' && this.props.data.status == DEMO_REQUEST_STATUS_LABELS[DEMO_REQUEST_STATUS_VALUES.pending] && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={() => { store.showDemoContacted = true; this.handleShowModal() }} >Request contacted?</a>}
                {this.state.action === 'demo-request' && this.props.data.status == DEMO_REQUEST_STATUS_LABELS[DEMO_REQUEST_STATUS_VALUES.contacted] && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={() => { store.showDemoConverted = true; this.handleShowModal() }} >Request converted?</a>}
                {this.state.action === 'export-request' && this.props.data.status == EXPORT_REQUEST_STATUS_LABELS[EXPORT_REQUEST_STATUS_VALUES.pending] && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={() => { store.showExportContacted = true; this.handleShowModal() }} >Request contacted?</a>}
                {this.state.action === 'export-request' && this.props.data.status == EXPORT_REQUEST_STATUS_LABELS[EXPORT_REQUEST_STATUS_VALUES.contacted] && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={() => { store.showExportConverted = true; this.handleShowModal() }} >Request converted?</a>}

                {/* ICE verification actions - verify/un-verify */}
                {this.state.action === 'verify_ice' && (this.props.data.verify_status == ICE_VERIFICATION_STATUS.unVerified) &&  <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={this.handleShowModal}>Verify</a>}
                {this.state.action === 'verify_ice' && (this.props.data.verify_status == ICE_VERIFICATION_STATUS.verified) && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={this.handleShowModal}>Un-verify</a>}

                {/* Resource actions - activate/deactivate */}
                {this.state.action === 'toggle-resource' && <a tabIndex='1' style={{marginLeft: '5px'}} className="btn-delete" onClick={this.handleShowModal}>{this.props.data.active ? "Deactivate" : "Activate"}</a>}
            </div>
        )
    }
}

export default ActionRenderer
