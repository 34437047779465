import React, { Component } from 'react';
import { observer } from 'mobx-react';
// helpers
import store from '../../../store';
import { isObjectEmpty } from '../../../utility/helpers';
// apis
import ecosystemApi from '../../../apis/ecosystemApi';


class ViewDefaultFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entityRelationData: {}
        }
    }

    async componentDidUpdate() {
        if (store.defaultFilterModalData && isObjectEmpty(this.state.entityRelationData)) {
            const entityRelation = store?.defaultFilterModalData?.entity_relation
            const entityRelationData = await ecosystemApi.getEntityDetails(entityRelation.id, entityRelation?.type?.toLowerCase());
            entityRelationData.type = entityRelation.type;
            this.setState({
                entityRelationData
            });
        }
    }

    handleCloseModal = () => {
        $('#defaultFilterModal').modal('hide');
    }

    renderData = () => {
        const {locations, primaryIndustries} = this.props;

        let content = <div style={{ textAlign: 'center' }}>No Default Filter Selected</div>

        if (store.defaultFilterModalData) {

            const arrFilters = Object.keys(store.defaultFilterModalData.filters).map((key, index) => {
                let data = []

                if (key === 'hq_id' && locations?.length > 0) {
                    data = locations.filter(location => store.defaultFilterModalData.filters[key].includes(location.id))
                }
                if (key === 'primary_industry' && primaryIndustries?.length > 0) {
                    data = primaryIndustries.filter(primaryIndustry => store.defaultFilterModalData.filters[key].includes(primaryIndustry.id))
                }

                return (
                    <li key={index}>
                        <b>{key}</b>: {data.map((item, index) => {
                            return <span key={index}>{item.name} ({item.id})</span>
                        })}
                    </li>
                )
            });

            const entityRelationContent = (
                <>
                    <li>
                        <b>Name</b>: {this.state.entityRelationData.name}
                    </li>
            		<li>
                        <b>Type</b>: {this.state.entityRelationData.type}
                    </li>
                </>
            );

            const hasDirectoryTag =
             (
                 <li >
                     <b>{store.defaultFilterModalData.has_directory_tags ? 'Yes' : 'None'}</b>
                 </li>
             );

            content = (
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th scope="col">Filters</th>
                            <td>
                                {arrFilters}
                            </td>
                        </tr>
                        <tr>
                            <th scope="col">Entity Relation</th>
                            <td>
                                <ul>
                                    {entityRelationContent}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col">Directory Tag</th>
                            <td>
                                <ul>
                                    {hasDirectoryTag}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
        return content;
    }

    render() {
        return (
            <div className="modal fade" id="defaultFilterModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Default Filter</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ViewDefaultFilterModal);
