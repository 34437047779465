import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';

// helpers
import store from '../../../store';

// constants
import { FUNDING_BREAKDOWNS_COLUMN_HEADERS } from '../../../constant';

// components
import FormParent from '../../FormParent';
import FundingBreakdownActionRenderer from '../fundingActions/FundingBreakdownActionRenderer';
import InvestorModalActionRenderer from '../fundingActions/InvestorModalActionRenderer';
import InvestorsViewModal from '../fundingPopups/InvestorsViewModal';
import FundingBreakdownModal from '../fundingPopups/FundingBreakdownModal';
import InvestorsEditModal from '../fundingPopups/InvestorsEditModal';

// apis
import Api from '../../../utility/api';

class FundingBreakdowns extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: FUNDING_BREAKDOWNS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: FundingBreakdownActionRenderer,
                InvestorsModalRenderer: InvestorModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },
            hq_data: ''
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.entity_type && nextProps.entity_type != 'USER' && nextProps.entity_id) {
            let type = nextProps.entity_type.toLowerCase();
            try {
                const data = await Api.request(`${type}/${nextProps.entity_id}?fields=id^,name^,hq_data`, 'GET', {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                })
                let arr = {
                    value: data.data.hq_data.id,
                    label: data.data.hq_data.name
                }
                this.setState({ hq_data: arr })
            } catch (error) {
                return;
            }
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    handleAddFundingBreakdown = () => {
        store.isFundingBreakdownEdit = false
        this.setState({ fundingError: false })

        store.fundingBreakdownDetails = {
            investment_vehicle_id: '',
            date_raised: '',
            amount: '',
            location_id: '',
            verification_status: '',
            comment: '',
            source_type_id: '',
            source_url: ''
        }

        $('#fundingBreakdownModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    handleEditInvestorFunding = () => {
        this.setState({ isInvestorEdit: true });

        $('#investorsViewModal').modal('hide');

        $('#investorsEditModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>FUNDING BREAKDOWNS</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleAddFundingBreakdown}>Add Funding Breakdown</button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.fundingBreakdownsData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                components={this.state.components}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <FundingBreakdownModal gridApi={this.gridApi} location_id={this.state.hq_data} />
                <InvestorsEditModal />
                <InvestorsViewModal handleEditInvestorFunding={this.handleEditInvestorFunding} />
            </div>
        )
    }
}

export default observer(FundingBreakdowns);
