import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import { SUBSCRIPTIONS_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

// apis
import subscriptionApi from '../../apis/subscriptionApi';

class Subscriptions extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUBSCRIPTIONS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },

            keyword: '',
            showKeywords: false,
            showtype: false,
            type: '',
            types: [],
            typeStore: [],
            filters: {
                type: []
            },
            filterFields: [
                { 'key': 'type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            //if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype: false }) }
        })

        const paramFields = ['id', 'name', 'type_data', 'duration', 'price', 'active', 'currency'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData('subscription', store.fields);

        subscriptionApi.getSubscriptionTypes().then(
            response => {
                if (response && response.types) {
                    const types = response.types.map(type => {
                        return { value: type.id, label: type.name };
                    });

                    this.setState({types});
                }
            }
        );

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            //if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showtype: false }) }
        })
    }
    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SUBSCRIPTIONS</h3>
                            <div className='filter-container'>

                                {/*<div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node} className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times " style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div> */}

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.type = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Type"
                                                        value={this.state.type}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'type')}
                                                        onFocus={() => { this.setState({ showDateFounded: false, showtype: true, showIndustries: false }) }} />
                                                    {this.state.showtype && this.state.types ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.type.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.type.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'type')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container' style={{ maxHeight: 150 }}>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.types.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'type')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' style={{ maxHeight: 150 }} >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('type')}>Clear</a> | <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showtype: false }); this.handleSearchFilters('subscription') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showtype ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showtype: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn" onClick={() => this.handleClearAllFilters('subscription')}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(Subscriptions)
