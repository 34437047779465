import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { DATA_EXPORT_POLICY_TABLE_COLUMN_HEADERS, SELECT_STYLES, SELECT_THEME_PRIMARY, SELECT_STYLES_ERROR } from '../../../constant';

// apis
import resourceApi from '../../../apis/resourceApi';
import dataExportApi from '../../../apis/dataExportApi';

// components
import FormParent from '../../FormParent';

function SubActionRenderer(props) {
    return (
        <>
            <a className="btn-edit" style={{ marginRight: 10 }} onClick={() => props.context.componentParent.handleEditPolicy(props.data)}>Update</a>
            <a className="btn-edit" onClick={() => props.context.componentParent.handleRemovePolicy(props.data)}>Remove</a>
        </>
    )
}

class PoliciesTable extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: DATA_EXPORT_POLICY_TABLE_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: SubActionRenderer },
            defaultColDef: { suppressMovable: true },
            // options
            productTypes: [],
            fields: [],
            selectedField: '',
            // form data
            modalFields: {
                data_set_type: '',
                limit: '',
                fields: []
            },
            // edit flags
            uniqueId: '',
            isFormEdit: false,
            // error
            isFieldEmpty: false,
        }
    }

    async componentDidMount() {
        await this.getDataExportProducts();
    }

    getDataExportProducts = async () => {
        dataExportApi.getProducts().then(
            response => {
                const dataSetTypes = response.map(type => {
                    return { value: type.data_set_type, label: type.data_set_type, resource: type.resource };
                })

                this.setState({ productTypes: dataSetTypes });
            }
        );
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit();
    }

    /**
     * Handel form field change
     */
    handleInputChange = (event, fieldName = null) => {
        let value, name;

        if (event.target) {
            value   = event.target.value;
            name    = event.target.name;
        } else if (event.label) {
            value   = event;
            name    = fieldName;
        }

        const modalFields = { ...this.state.modalFields };
        modalFields[name] = value;
        this.setState({ modalFields });
    }

    /**
     * Handle data set type field change and get the related attributes/fields
     */
    handleSelectChange = (option) => {
        const tempModalFields = { ...this.state.modalFields };

        tempModalFields.data_set_type = option;
        tempModalFields.fields = [];

        this.setState({ modalFields: tempModalFields, selectedField: '' }, () => {
            this.getAttributes(option.resource);
        });
    }

    /**
     * Get the data set type attributes/fields
     */
    getAttributes = async (resource = '') => {
        const resourceCode = resource.toLowerCase();
        const resourceName = `${resourceCode}-get`;

        resourceApi.getResourceIdByCode(resourceCode, resourceName).then(
            response => {
                const attributes = response.attributes[resourceCode].map(item => {
                    return { value: item.key, label: item.label };
                });

                this.setState({ fields: attributes });
            }
        );

        if (resourceCode === 'funding-breakdown') {
            const strResourceCode = 'fund';
            const strResourceName = `${strResourceCode}-get`;

            resourceApi.getResourceIdByCode(strResourceCode, strResourceName).then(
                response => {
                    const attributes = response.attributes[strResourceCode].map(item => {
                        return { value: item.key, label: item.label };
                    });

                    this.setState({ fields: [...this.state.fields, ...attributes] });
                }
            );
        }
    }

    /**
     * Handle the fields selection
     */
    handleAddFieldOption = (option) => {
        const tempModalFields = {...this.state.modalFields};

        tempModalFields.fields.push(option.value);

        this.setState({
            selectedField: option,
            modalFields: tempModalFields
        })
    }

    /**
     * Handle the fields removal
     */
    handleRemoveField = (index) => {
        let modalFields = { ...this.state.modalFields };
        let fields = [...modalFields.fields];

        fields.splice(index, 1);

        modalFields.fields = fields;

        this.setState({
            modalFields
        });
    }

    handleAddPolicy = () => {
        const { modalFields } = this.state;
        const { isGeneralPolicy } = this.props;

        if (modalFields.fields.length == 0 || !modalFields.data_set_type || (!modalFields.limit && !isGeneralPolicy)) {
            return this.setState({ isFieldEmpty: true });
        }

        const tempObj           = {};
        tempObj.uniqueId        = new Date().getTime();
        tempObj.data_set_type   = modalFields.data_set_type.value;
        tempObj.limit           = modalFields.limit;
        tempObj.fields          = modalFields.fields;

        const rowData = [tempObj, ...this.props.data];
        this.gridApi.setGridOption('rowData',rowData);

        this.props.onSuccess(tempObj);
        this.handleClearForm();
        $('#addDataExportPolicyModal').modal('hide');
    }

    handleUpdatePolicy = () => {
        const { modalFields, uniqueId } = this.state;
        const { isGeneralPolicy, data } = this.props;

        let tempData = [...data];

        if (modalFields.fields.length == 0 || !modalFields.data_set_type || (!modalFields.limit && !isGeneralPolicy)) {
            return this.setState({ isFieldEmpty: true });
        }

        const tempObj           = {};
        tempObj.uniqueId        = uniqueId;
        tempObj.data_set_type   = modalFields.data_set_type.value;
        tempObj.limit           = modalFields.limit;
        tempObj.fields          = modalFields.fields;

        tempData.map(policy => {
            if (policy.data_set_type == tempObj.data_set_type) {
                return tempObj;
            } else {
                return policy;
            }
        });

        const rowData = [...tempData];
        this.gridApi.setGridOption('rowData',rowData);

        this.props.onSuccess(tempObj, tempObj.data_set_type);
        this.handleClearForm();
        $('#addDataExportPolicyModal').modal('hide');
    }

    handleEditPolicy = (data) => {
        const objType = this.state.productTypes.find(type => type.label == data.data_set_type);
        const resource = objType.resource;

        const modalFields = {
            fields: data.fields,
            limit: data.limit,
            data_set_type: { value: data.data_set_type, label: data.data_set_type }
        };

        this.setState({
            modalFields, isFormEdit: true, uniqueId: data.uniqueId
        }, () => {
            this.getAttributes(resource);
            this.showModal();
        });
    }

    handleRemovePolicy = (data) => {
        const index = this.props.data.findIndex(item => item.uniqueId == data.uniqueId);

        if (index > -1) {
            const clonedData = [...this.props.data];
            clonedData.splice(index, 1);
            this.props.onPolicyRemove(clonedData);
        }
    }

    handleClearForm = () => {
        const objResetData  = {
            data_set_type: '',
            limit: '',
            fields: []
        };

        this.setState({
            modalFields: objResetData,
            isFieldEmpty: false,
            isFormEdit: false,
            uniqueId: '',
            selectedField: '',
            fields: []
        });
    }

    showModal = () => {
        $('#addDataExportPolicyModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });
    }

    renderModal = () => {
        const { isFieldEmpty, modalFields, selectedField } = this.state;

        return (
            <div className="modal fade" id="addDataExportPolicyModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{'ADD POLICY'}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Data Set Type <span className="required">*</span></label>
                                    <Select
                                        placeholder='Please select a data set type'
                                        styles={(isFieldEmpty && !modalFields.data_set_type) ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={modalFields.data_set_type}
                                        onChange={this.handleSelectChange}
                                        options={this.state.productTypes}
                                    />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Limit {!this.props.isGeneralPolicy && <span className="required">*</span>}</label>
                                    <input
                                        type="number"
                                        className={(!this.props.isGeneralPolicy && isFieldEmpty && !modalFields.limit) ? "form-control input-error" : "form-control"}
                                        name='limit'
                                        disabled={this.props.isGeneralPolicy}
                                        value={modalFields.limit}
                                        onChange={this.handleInputChange} />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Fields <span className="required">*</span></label>
                                    <Select
                                        placeholder='Select allowed Fields'
                                        styles={(isFieldEmpty && !modalFields.fields) ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={selectedField}
                                        onChange={this.handleAddFieldOption}
                                        options={this.state.fields}
                                    />
                                    {modalFields.fields ?
                                        modalFields.fields.length > 0 &&
                                        <div className='permission-policy-list-wrapper'>
                                            {modalFields.fields.map((item, i) => {
                                                return <li key={i}>{item} <i className='fa fa-times-circle' onClick={() => this.handleRemoveField(i)}></i></li>
                                            })}
                                        </div> : null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClearForm}>Close</button>
                            {
                                this.state.isFormEdit ?
                                    <button type="button" className="btn btn-primary" onClick={this.handleUpdatePolicy}>Update</button>
                                    :
                                    <button type="button" className="btn btn-primary" onClick={this.handleAddPolicy}>Add</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>Policy Details</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.showModal}>Add Policy</button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={this.props.data}
                                domLayout='autoHeight'
                                context={this.state.context}
                                components={this.state.components}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                {this.renderModal()}
            </div>
        )
    }
}

export default observer(PoliciesTable)
