import axios from 'axios';
import store from '../store';
import { API } from '../constant';
import { prepareSelectParam } from '../utility/queryParams';


const entityEmployeeCountApi = {
    getEntityEmployeeCountDetails: (entityEmployeeCountId = 0, paramFields) => {
        const fields = prepareSelectParam(paramFields);
        const url = `${API}/entity-employee-count/${entityEmployeeCountId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };
        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    }
}
export default entityEmployeeCountApi;
