import React, { Component } from 'react'
import { toJS, get } from 'mobx';
import store from '../../store'

class TeamActionRenderer extends Component {
    handleEdit = () => {
        store.isTeamEdit = true;
        store.currentTeamId = this.props.data.id;
        store.teamRowIndex = this.props.node.rowIndex;

        this.props.context.componentParent.setState({ teamError: false });

        let obj = { ...this.props.data };

        Object.keys(this.props.data).map(key => {
            if (key === 'user_id') {
                obj[key] = { 'value': this.props.data.uid, 'label': this.props.data.user_id, email: this.props.data.email }
            }
            else if (key === 'role_id') {
                obj[key] = { 'value': this.props.data.rid, 'label': this.props.data.role_id }
            }
            else if (key === 'team_role_id') {
                obj[key] = { 'value': this.props.data.trid, 'label': this.props.data.team_role_id }
            }
            else if (key === 'is_primary') {
                obj[key] = { 'value': this.props.data.is_primary, 'label': key}
            }
        })

        store.teamDetails = obj
        

        if (store.teamData[this.props.node.rowIndex] && store.teamData[this.props.node.rowIndex].isInvite) {
            this.handleInviteEdit();
            store.isInviteEdit = true;
            $('#inviteUserModal').modal('show')
        } else {
            $('#addTeamModal').modal('show')
        }
    }

    handleDelete = () => {
        if (!store.isEdit && store.teamData[this.props.node.rowIndex] && store.teamData[this.props.node.rowIndex].isInvite) {
            this.deleteInviteAPI();
        }

        store.currentTeamId = this.props.data.id
        this.props.context.componentParent.handleDeleteTeam(this.props.node.rowIndex)
    }

    deleteInviteAPI = () => {          
        const userInvitations   = get(store, 'userInvitations');
        const clickedItem       = store.teamData[this.props.node.rowIndex];
        const index             = userInvitations.findIndex(item => item.email === get(clickedItem, "email"));

        store.userInvitations.splice(index, 1);
    }

    handleInviteEdit = () => {
        
    }

    render() {
        return (
            <div>
                <a tabIndex='1' className="btn-edit" onClick={this.handleEdit}><i className='fas fa-pencil-alt'></i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default TeamActionRenderer

