import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import { TAXONOMY_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Pagination from '../reusable/Pagination'
import TaxonomyModal from '../reusable/TaxonomyModal'
import Home from '../Home'
import RBAC from '../../utility/rbac'

let isAddAllowed = false

class Taxonomy extends Parent {
    constructor(props) {
        super(props)
        isAddAllowed    = RBAC.hasAccess('taxonomy-type', 'post', ':param');

        this.state      = {
            columnDefs: TAXONOMY_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            name: '',
            shownames: false,
            name: [],
            errorName: false,
            taxonomy: '',
            filters: {
                name: [],
            },
            filterFields: [
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
            ]
        }
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showname: false }) }
        })
        store.isTaxonomyList = true

        let paramFields = ['id', 'name']
        store.fields = prepareSelectParam(paramFields)

        this.fetchListData('taxonomy-type', store.fields)
    }

    componentWillUnmount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showname: false }) }
        })
    }

    handleAdd = () => {
        store.taxonomyTitle = 'TAXONOMY';
        store.isEdit = false;
        store.isAdd = true;

        $('#taxonomyModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>TAXONOMIES</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ shownames: true })}
                                                onKeyUp={(event) => this.handleKeywordEnter(event, 'name')}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.shownames && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                            </div>

                            {isAddAllowed &&
                                <button className="btn-secondary bottom-btn" onClick={this.handleAdd}>ADD TAXONOMY</button>
                            }

                            <div className='ag-theme-balham' style={{ width: '100%', marginTop: 20 }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <TaxonomyModal gridApi={this.gridApi}/>

                    </div>
                }
            </div>
        )
    }
}


export default observer(Taxonomy)
