import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import { SUB_INDUSTRY_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, PARENT_INDUSTRY_ID } from '../../constant'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Home from '../Home'
import { getSubIndustries } from '../../utility/taxonomiesApis'

class SubIndustry extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUB_INDUSTRY_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            keyword: '',
            showKeywords: false,
            filters: {
                keyword: [],
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {
        store.isTaxonomyList = false
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
        })
        const parentId = store.industryParentId;
        const currentId = store.currentId;
        let arr = [];

        if (parentId === PARENT_INDUSTRY_ID) {
            const subIndustries = await getSubIndustries(currentId);
            arr = subIndustries.map(industry => ({
                id: industry.value,
                name: industry.label,
                value: industry.value
            }))
        }
        store.rowData = arr;
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>
                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SUB INDUSTRIES</h3>

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d', marginTop: 20 }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                    </div>
                }

            </div>
        )
    }
}
export default observer(SubIndustry)
