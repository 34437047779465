import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';

// constants
import { ALLOWED_QUERY_OPERATOR } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';
import { SUBSCRIPTION_ADDONS_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';

// helpers
import store from '../../store';
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams';

// components
import Home from '../Home';
import Pagination from '../reusable/Pagination';
import SideNav from '../SideNav';
import Parent from '../Parent';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import ActionRenderer from '../reusable/ActionRenderer';

class SubscriptionAddons extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUBSCRIPTION_ADDONS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },

            // filters
            name: '',
            filters: {
                name: []
            },
            filterFields: [
                { key: 'name', op: ALLOWED_QUERY_OPERATOR.substring, value: [] },
            ]
        }

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        const paramFields = ['id', 'name', 'price', 'active'];
        const paramFilters = [{ key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }];

        store.fields = prepareSelectParam(paramFields);
        store.currentFilters = prepareFilterParam(paramFilters);

        this.fetchListData(API_PATH.subscriptionAddon, store.fields);
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Subscription Addons</h3>
                            <div className='filter-container'>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div ref={node => this.name = node} className="col-md-12 col-sm-12 col-12 filter-firstline filter-spacing" >
                                            <div style={{ width: '100%' }}>
                                                <input ref={node => this.input = node}
                                                    className='filter-input input-border'
                                                    placeholder="Search by addon name"
                                                    value={this.state.name}
                                                    onFocus={() => this.setState({ showNames: true })}
                                                    onKeyUp={event => this.handleKeywordEnter(event, 'name')}
                                                    onChange={e => this.setState({ name: e.target.value })} />
                                                {this.state.showNames && this.state.filters.name.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.name.map((item, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                    {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            {this.state.name.length > 0 &&
                                                <i className="fa fa-times "
                                                    style={{ paddingRight: 10 }}
                                                    onClick={() => {
                                                        this.input.focus()
                                                        this.setState({ name: '' })
                                                    }}>
                                                </i>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn" onClick={() => this.handleClearAllFilters(API_PATH.subscriptionAddon)}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(SubscriptionAddons);
