import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import { FUNDS_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, DELETE_OPTIONS, OPTIONS, ENTITY_TYPES } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareFilterParam, prepareSelectParam } from '../../utility/queryParams';
import { isObjectEmpty } from '../../utility/helpers';

// apis
import { getIndustries, getLocations, getEntities } from '../../utility/commonApis';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import Modal from '../reusable/Modal';

class Funds extends Parent {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: FUNDS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            showindustry_filter: false,
            showgeo_focus_filter: false,
            showkeyword: false,

            keyword: '',

            industry_filters: [],
            industry_filterStore: [],
            geo_focus_filters: [],
            geo_focus_filterStore: [],

            industry_filter: '',
            industry_filters: [],
            geo_focus_filter: '',
            geo_focus_filters: [],

            entities: '',
            managerEntityType: '',
            managerEntityId: '',

            filters: {
                keyword: [],
                industry_filter: [],
                geo_focus_filter: [],
                status: [],
                is_deleted: [],
                entity_type: [],
                entity_id: []
            },
            filterFields: [
                { key: 'industry_filter', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'geo_focus_filter', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'keyword', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'is_deleted', op: ALLOWED_QUERY_OPERATOR.equal, value: [0] },
                { key: 'status', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'entity_type', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
                { key: 'entity_id', op: ALLOWED_QUERY_OPERATOR.equal, value: [] },
            ]
        };

        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry_filter: false }); }
            if (this.loc) { if (!this.loc.contains(e.target)) this.setState({ showgeo_focus_filter: false }); }
        });

        // funds request fields
        const paramFields = [
            'id',
            'name',
            'entity_id',
            'entity_type',
            'entity_data',
            'status',
            'created_at',
            'updated_at',
        ];

        store.fields = prepareSelectParam(paramFields);
        store.currentFilters = prepareFilterParam(this.state.filterFields);

        this.fetchListData(API_PATH.fund, store.fields);

        getIndustries().then(res => {
            this.setState({ industry_filters: res, industry_filterStore: res });
        });

        getLocations().then(res => {
            this.setState({ geo_focus_filters: res, geo_focus_filterStore: res });
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.industry) { if (!this.industry.contains(e.target)) this.setState({ showindustry_filter: false }); }
            if (this.loc) { if (!this.loc.contains(e.target)) this.setState({ showgeo_focus_filter: false }); }
        });
    }

    clearFilters = () => {
        this.setState({
            industry_filter: '',
            geo_focus_filter: '',
            keyword: '',
            managerEntityType: '',
            managerEntityId: ''
        }, () => this.handleClearAllFilters(API_PATH.fund));
    };

    setEntities = async (option, callback) => {
        const { managerEntityType } = this.state;
        let value = "";

        if (!managerEntityType || isObjectEmpty(managerEntityType)) {
            return;
        }

        if (option && option.length > 2) {
            value = option;
        }

        const entities = await getEntities(managerEntityType.value.toLowerCase(), value);

        this.setState({ entities });

        if (callback) {
            callback(entities);
        }
    }

    handleEntityTypeChange = (option) => {
        this.setState({
            managerEntityType: option,
        }, () => {
            this.setEntities();
            this.handleSingleSelectFilter(option, 'entity_type');
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>FUNDS</h3>
                            <div className='filter-container'>

                                <div className='filter-row' >
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showkeyword: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showkeyword && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by manager type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.managerEntityType}
                                            onChange={this.handleEntityTypeChange}
                                            options={ENTITY_TYPES}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Filter by manager name'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.managerEntityId}
                                            defaultOptions={this.state.entities}
                                            loadOptions={(option, callback) => this.setEntities(option, callback)}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'entity_id')}
                                        />
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }} >

                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing">
                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.industry = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Industry"
                                                        value={this.state.industry_filter}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'industry_filter')}
                                                        onFocus={() => { this.setState({ showindustry_filter: true }); }} />
                                                    {this.state.showindustry_filter && this.state.industry_filters ?
                                                        <div className='filter-dropdown w-100' >
                                                            <div >
                                                                {this.state.filters.industry_filter.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.industry_filter.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'industry_filter')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>;
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.industry_filters.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => this.handleSelectFilter(item, 'industry_filter')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('industry_filter')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showindustry_filter: false }); this.handleSearchFilters(API_PATH.fund); }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showindustry_filter ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry_filter: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry_filter: true })} ></i>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.loc = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="Search by Geo Focus"
                                                        value={this.state.geo_focus_filter}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'geo_focus_filter')}
                                                        onFocus={() => { this.setState({ showgeo_focus_filter: true }); }} />
                                                    {this.state.showgeo_focus_filter && this.state.geo_focus_filters ?
                                                        <div className='filter-dropdown w-100' >
                                                            <div >
                                                                {this.state.filters.geo_focus_filter.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.geo_focus_filter.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'geo_focus_filter')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>;
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.geo_focus_filters.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => this.handleSelectFilter(item, 'geo_focus_filter')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn ' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('geo_focus_filter')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showgeo_focus_filter: false }); this.handleSearchFilters(API_PATH.fund); }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showgeo_focus_filter ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showgeo_focus_filter: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showgeo_focus_filter: true })} ></i>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by deleted status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.is_deleted}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'is_deleted')}
                                                    options={DELETE_OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                        <div className='filter-firstline'>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    placeholder='Filter by active status'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    value={this.state.status}
                                                    onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                                    options={OPTIONS}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn mr-0" onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        );
    }
}

export default observer(Funds);
