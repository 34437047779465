import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import DatePicker from 'react-datepicker';

// helpers
import store from '../../../store';
import { prepareSelectParam, prepareFilterParam } from '../../../utility/queryParams';
import { isObjectEmpty } from '../../../utility/helpers';

// constants
import { FUNDING_VERIFICATION_COLUMN_HEADERS, SELECT_STYLES, SELECT_THEME_PRIMARY, TAXONOMIES, ALLOWED_QUERY_OPERATOR, ENTITY_TYPES, FUNDING_STATUS_FILTER_OPTIONS, MIN_SEARCH_LENGTH } from '../../../constant';
import { API_PATH } from '../../../utility/constants/apiPath';

// apis
import { getTaxonomyData, getEntities } from '../../../utility/commonApis';

// components
import Parent from '../../Parent';
import Home from '../../Home';
import SideNav from '../../SideNav';
import Sort from '../../reusable/Sort';
import Pagination from '../../reusable/Pagination';
import FundingStatusActionRenderer from '../fundingActions/FundingStatusActionRenderer';
import InvestorModalActionRenderer from '../fundingActions/InvestorModalActionRenderer';
import InvestorsViewModal from '../fundingPopups/InvestorsViewModal';
import FundingStatusPopup from '../fundingPopups/FundingStatusPopup';
import Modal from '../../reusable/Modal';

class FundingVerification extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: FUNDING_VERIFICATION_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: FundingStatusActionRenderer,
                InvestorsModalRenderer: InvestorModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            // funding id
            id: '',
            funding_id: '',
            entity_id: '',
            verification_status: '',
            showstartup_id: false,
            showid: false,
            showentity_id: false,
            showdate_raised: false,
            showcreated_at: false,
            entities: [],
            startup_ids: [],
            funding_stage: '',
            funding_stages: [],
            funding_stageStore: [],
            startup_idStore: [],
            showfunding_stage: false,
            investorEntityType: '',
            investor_filter: '',
            fundingFromDate: '',
            fundingToDate: '',
            createdFromDate: '',
            createdToDate: '',
            filters: {
                id: [],
                funding_id: [],
                entity_id: [],
                funding_stage: [],
                startup_id: [],
                verification_status: [],
                date_raised: [],
                created_at: [],
                investor_filter: [],
                investment_vehicle_id: []
            },
            filterFields: [
                { 'key': 'id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'funding_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'startup_id', 'key': 'investedin_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'investor_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'funding_stage', 'key': 'funding_stage_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'name': 'entity_id', 'key': 'investedin_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'date_raised', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'date_raised', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'verification_status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [0] },
                { 'key': 'investment_vehicle_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'investor_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
            if (this.startup_id) { if (!this.startup_id.contains(e.target)) this.setState({ showstartup_id: false }) }
            if (this.entity_id) { if (!this.entity_id.contains(e.target)) this.setState({ showentity_id: false }) }
            if (this.funding_id) { if (!this.funding_id.contains(e.target)) this.setState({ showid: false }) }
        })

        store.currentSort = 'created_at^:DESC';

        this.getInitialData()

        getTaxonomyData(TAXONOMIES.fundingStage).then(res => {
            this.setState({ funding_stages: res, funding_stageStore: res })
        });

        getTaxonomyData(TAXONOMIES.investmentVehicles).then(res => {
            this.setState({ investment_vehicles: res});
        });

        this.setStartupIds();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.stage) { if (!this.stage.contains(e.target)) this.setState({ showfunding_stage: false }) }
            if (this.startup_id) { if (!this.startup_id.contains(e.target)) this.setState({ showstartup_id: false }) }
            if (this.entity_id) { if (!this.entity_id.contains(e.target)) this.setState({ showentity_id: false }) }
            if (this.funding_id) { if (!this.funding_id.contains(e.target)) this.setState({ showid: false }) }
        })
    }

    getInitialData = () => {
        // entity_id is added in-order for the filter to work - don't remove it
        const paramFields = ['id', 'funding_id', 'entity_id', 'entity_type', 'investedin_data', 'funding_stage_data', 'investment_vehicle_data',
            'date_raised', 'amount', 'verified_by_data', 'verified_at', 'verification_status', 'source_url', 'comment', 'investors_data',
            'created_by_data', 'investing_entity_data', 'created_by_admin'];

        store.fields = prepareSelectParam(paramFields);
        store.currentFilters = prepareFilterParam(this.state.filterFields);

        this.fetchListData('funding-verification', store.fields, store.type);
    }

    setStartupIds = async (e) => {
        let value = ""

        if (e && e.target.value.length >= MIN_SEARCH_LENGTH) {
            value = e.target.value;
        }

        const startups = await getEntities('startup', value)

        this.setState({
            startup_ids: startups,
            startup_idStore: startups
        })
    }

    setEntities = async (option, callback) => {
        const { investorEntityType } = this.state;
        let value = ""

        if (!investorEntityType || isObjectEmpty(investorEntityType)) {
            return;
        }

        if (option && option.length > 2) {
            value = option;
        }

        const entities = await getEntities(investorEntityType.value.toLowerCase(), value);

        this.setState({ entities });

        if (callback) {
            callback(entities);
        }
    }

    handleEntityTypeChange = (option) => {
        this.setState({
            investorEntityType: option
        }, () => this.setEntities());
    }

    clearFilters = () => {
        this.setState({
            fundingFromDate: '',
            fundingToDate: '',
            createdFromDate: '',
            createdToDate: '',
            investorEntityType: '',
            verification_status: '',
            investor_filter: '',
            investment_vehicle_id: '',
            funding_stage: '',
        }, () =>
            this.handleClearAllFilters('funding-verification')
        );
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>FUNDING VERIFICATION</h3>
                            <div className='filter-container'>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.startup_id = node} className='filter-firstline' style={{ width: '100%' }}>
                                            <div>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Search by startup name"
                                                    onChange={this.setStartupIds}
                                                    onFocus={() => { this.setState({ showstartup_id: true }) }} />
                                                {this.state.showstartup_id && this.state.startup_ids ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.startup_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.startup_id.map((owner, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'startup_id')}>
                                                                            {owner}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.startup_ids.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'startup_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('startup_id', API_PATH.fundingBreakdown)}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showstartup_id: false }); this.handleSearchFilters(API_PATH.fundingBreakdown, null, store.type) }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>

                                            {this.state.showstartup_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstartup_id: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showstartup_id: true })} ></i>}
                                        </div>
                                    </div>
                                </div>

                                {/* Investor search filter */}
                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Please select investor type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.investorEntityType}
                                            onChange={this.handleEntityTypeChange}
                                            options={ENTITY_TYPES}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <AsyncSelect
                                            placeholder='Please Select or type investor name to search'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            cacheOptions
                                            value={this.state.investor_filter}
                                            defaultOptions={this.state.entities}
                                            loadOptions={(option, callback) => this.setEntities(option, callback)}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'investor_filter')}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by funding stage'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.funding_stage}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'funding_stage', API_PATH.fundingBreakdown)}
                                            options={this.state.funding_stages}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <Select
                                            placeholder='Filter by investment vehicle'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.investment_vehicle_id}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'investment_vehicle_id', API_PATH.fundingBreakdown)}
                                            options={this.state.investment_vehicles}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by verification status'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.verification_status}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'verification_status', API_PATH.fundingBreakdown)}
                                            options={FUNDING_STATUS_FILTER_OPTIONS}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' style={{ width: '100%' }}>
                                            <input ref={node => this.entity_id = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search by Invested in ID "
                                                value={this.state.entity_id}
                                                onFocus={() => this.setState({ showentity_id: true })}
                                                onKeyUp={(e) => this.handleInputEnter(e, 'entity_id')}
                                                onChange={e => this.setState({ entity_id: e.target.value })} />
                                            {this.state.showentity_id && this.state.filters.entity_id.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.entity_id.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'entity_id')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.entity_id.length > 0 &&
                                                <i className="fa fa-times "
                                                    style={{ paddingRight: 10 }}
                                                    onClick={() => {
                                                        this.input.focus()
                                                        this.setState({ entity_id: '' })
                                                    }}>
                                                </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' style={{ width: '100%' }}>
                                            <input ref={node => this.funding_id = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search by Funding ID "
                                                value={this.state.funding_id}
                                                onFocus={() => this.setState({ showfunding_id: true })}
                                                onKeyUp={(e) => this.handleInputEnter(e, 'funding_id')}
                                                onChange={e => this.setState({ funding_id: e.target.value })} />
                                            {this.state.showfunding_id && this.state.filters.funding_id.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.funding_id.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'funding_id')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.funding_id.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ funding_id: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' style={{ width: '100%' }}>
                                            <input ref={node => this.id = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search by Funding Breakdown ID "
                                                value={this.state.id}
                                                onFocus={() => this.setState({ showid: true })}
                                                onKeyUp={(e) => this.handleInputEnter(e, 'id')}
                                                onChange={e => this.setState({ id: e.target.value })} />
                                            {this.state.showid && this.state.filters.id.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.id.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'id')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.id.length > 0 &&
                                                <i className="fa fa-times "
                                                    style={{ paddingRight: 10 }}
                                                    onClick={() => {
                                                        this.input.focus()
                                                        this.setState({ id: '' })
                                                    }}>
                                                </i>
                                        }
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>

                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.date = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Created At Select From date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        isClearable={this.state.createdFromDate}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.createdFromDate}
                                                        onChange={(date) => this.handleDateChange(date, 'createdFromDate', 'created_at', ALLOWED_QUERY_OPERATOR.gte)} />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.date = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Created At Select To date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        minDate={this.state.createdFromDate}
                                                        maxDate={new Date()}
                                                        isClearable={this.state.createdToDate}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.createdToDate}
                                                        onChange={(date) => this.handleDateChange(date, 'createdToDate', 'created_at', ALLOWED_QUERY_OPERATOR.lte)} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>

                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.date = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Funding Date Select From date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        isClearable={this.state.fundingFromDate}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.fundingFromDate}
                                                        onChange={(date) => this.handleDateChange(date, 'fundingFromDate', 'date_raised', ALLOWED_QUERY_OPERATOR.gte)} />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.date = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <DatePicker
                                                        placeholderText="Funding Date Select To date"
                                                        className='filter-datepicker-form-control'
                                                        dateFormat="yyyy/MM/dd"
                                                        minDate={this.state.fundingFromDate}
                                                        maxDate={new Date()}
                                                        isClearable={this.state.fundingToDate}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={this.state.fundingToDate}
                                                        onChange={(date) => this.handleDateChange(date, 'fundingToDate', 'date_raised', ALLOWED_QUERY_OPERATOR.lte)} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2'
                                            className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn"
                                            onClick={this.clearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination
                                path={API_PATH.fundingBreakdown}
                                details="funding-verification"
                            />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal getInitialData={this.getInitialData}/>
                        <InvestorsViewModal/>
                        <FundingStatusPopup getInitialData={this.getInitialData}/>

                    </div>
                }
            </div>
        )
    }
}

export default observer(FundingVerification);
