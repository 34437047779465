import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';

// helpers, constants
import store from '../../../store';
import { SUBSCRIPTION_ROLES_HEADERS } from '../../../constant';

// components
import FormParent from '../../FormParent';
import RoleActionRenderer from './RoleActionRenderer'
import RoleModal from './RoleModal';

// apis
import subscriptionApi from '../../../apis/subscriptionApi';

class SubscriptionRoles extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: SUBSCRIPTION_ROLES_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: RoleActionRenderer },
            defaultColDef: { suppressMovable: true },
            isInvitePopupOpened: false
        }
    }

    componentDidMount() {
        store.subscriptionRoles = [];
        store.rolesData = [];

        if (store.isEdit && store.currentId) {
            subscriptionApi.getRoles(store.currentId).then(
                response => {
                    response.map(
                        role => {
                            const id = role.id;
                            const roleData = role.role_data;
                            store.rolesData.push({ id: id, name: roleData.name });
                        }
                    );

                    this.gridApi.setGridOption('rowData',store.rolesData);
                }
            );
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    handleShowAddRoleModal = () => {
        this.setState({ error: false });

        $('#addRoleModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>SUBSCRIPTION ROLES</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddRoleModal}>
                                    Add Role
                            </button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.rolesData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                components={this.state.components}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                        <RoleModal gridApi={this.gridApi}/>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(SubscriptionRoles)
