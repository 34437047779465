import React, { Component } from 'react';


class AddLogoDimensionModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: '',
            width: '',
            update_default_size: { value: 1, label: 'Yes' },
            originalDimensions: null
        }
    }

    componentDidMount() {
        const { logoDimensions } = this.props;

        if (logoDimensions) {

            const dimensions = logoDimensions?.original_dimensions || logoDimensions?.value?.original_dimensions;

            this.setState({
                height: logoDimensions?.custom_dimensions?.height ? Number(logoDimensions.custom_dimensions.height) : '',
                width: logoDimensions?.custom_dimensions?.height ? Number(logoDimensions.custom_dimensions.width) : '',
                update_default_size: {
                    value: logoDimensions?.custom_dimensions?.update_default_size,
                    label: logoDimensions?.custom_dimensions?.update_default_size ? 'Yes' : 'No'
                },
                originalDimensions: dimensions
            });
        }
    }

    handleCloseModal = () => {
        this.handleClearFields();

        this.props.setParentState({
            showLogoDimensionModel: false
        });
    }

    handleClearFields = () => {

        this.setState({
            height: '',
            width: ''
        });

        $('#logoDimensionsModal').modal('hide');
    }

    handleAddLogoDimension = () => {
        const { height, width, update_default_size } = this.state;
        const { logoDimensions } = this.props;

        const obj = {
            original_dimensions: logoDimensions.original_dimensions,
            custom_dimensions: {
                height,
                width,
                update_default_size: update_default_size.value
            }
        };

        const option = { value: obj };

        this.props.handleAddLogoDimension(option, 'logo_dimensions');
        this.handleClearFields();
    }

    handleInputChange = (e) => {
        const InitialValue = Number(e.target.value);
        const value = Number(InitialValue.toFixed(2))

        this.setState({
            [e.target.name]: value
        });
        this.updateCorrespondingSize(e.target.name, value)
    }

    updateCorrespondingSize = (name, value) => {
        const { originalDimensions } = this.state;

        const baseWidth = originalDimensions?.width
        const baseHeight = originalDimensions?.height

        if (name === 'height') {
            if (baseWidth && baseHeight) {
                const ratio = baseWidth / baseHeight;
                const newWidth = Number(value * ratio).toFixed(2);
                this.setState({
                    width: Number(newWidth)
                });
            }
        }

        if (name === 'width') {
            if (baseWidth && baseHeight) {
                const ratio = baseHeight / baseWidth;
                const newHeight = Number(value * ratio).toFixed(2);
                this.setState({
                    height: Number(newHeight)
                });
            }
        }
    }

    handleSelectChange = (option, name) => {
        this.setState({
            [name]: option
        });
    }

    render() {
        return (
            <div className="modal fade" id="logoDimensionsModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Logo Dimension</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>



                        <div className="modal-body">

                            <p >
                                <span> <strong>For reference, the original dimensions of the Magnitt logo are:</strong></span><br />
								    <span> Width: 166</span>  <br />
								    <span> Height: 38</span>
                            </p>


                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Logo width</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name='width'
                                        placeholder="Add Logo Width"
                                        value={this.state.width}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Logo height</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name='height'
                                        placeholder="Add Logo Height"
                                        value={this.state.height}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleCloseModal}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddLogoDimension}>Add Logo Dimension</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddLogoDimensionModel
