import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';

// constants
import { ALLOWED_QUERY_OPERATOR, ACTIVE_STATUS, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PRODUCT_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import ActionRenderer from '../reusable/ActionRenderer';
import PolicyModalActionRenderer from './policy/PolicyModalActionRenderer';
import ViewPolicyModal from './policy/ViewPolicyModal';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';

class ApiProducts extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: API_PRODUCT_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: ActionRenderer,
                policyModalRenderer: PolicyModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            // filters
            active: '',
            role_id: [],
            roles: [],
            filters: {
                role_id: [],
                active: []
            },
            filterFields: [
                { 'key': 'role_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'active', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {

        const paramFields = ['id', 'name', 'active', 'duration', 'price', 'currency', 'policy'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.apiProduct, store.fields);
    }

    handleClearFilters = () => {
        this.setState({
            active: '',
            role_id: ''
        }, () => {
            this.handleClearAllFilters(API_PATH.apiProduct);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>API Products</h3>
                            <div className='filter-container'>


                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <Select
                                                placeholder='Filter by status'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.active}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'active')}
                                                options={ACTIVE_STATUS}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <ViewPolicyModal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(ApiProducts)
