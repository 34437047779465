export const NEWS_UTM_LINKS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true, suppressSizeToFit: true },
    { headerName: 'CATEGORY', field: 'category', resizable: true },
    { headerName: 'UTM LINK', field: 'utm_link', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true },
];

export const ORGANIZATION_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'LOCATION', field: 'location', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const ORGANIZATION_ROLE_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'ROLE', field: 'role', resizable: true },
    { headerName: 'ROLE ID', field: 'roleId', resizable: true },
    { headerName: 'SUBSCRIPTION ID', field: 'subscriptionId', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const ORGANIZATION_USERS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'USER ID', field: 'userId', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const API_PRODUCT_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'DURATION', field: 'duration', resizable: true },
    { headerName: 'PRICE', field: 'price', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'POLICY', field: 'policy', resizable: true, cellRenderer: "policyModalRenderer" },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const API_CLIENTS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', resizable: true },
    { headerName: 'CLIENT NAME', field: 'name', resizable: true },
    { headerName: 'CLIENT ID', field: 'clientId', resizable: true },
    { headerName: 'USER', field: 'user', resizable: true },
    { headerName: 'ORGANIZATION', field: 'organization', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const SUBSCRIPTION_ADDONS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'PRICE', field: 'price', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const VALUATION_CALCULATION_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'USER', field: 'created_by', resizable: true },
    { headerName: 'START TIME', field: 'start_time', resizable: true },
    { headerName: 'END TIME', field: 'end_time', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'ERROR', field: 'error', resizable: true },
    { headerName: 'CALCULATION DETAILS', sortable: false, cellRenderer: "valuationCalculationLogModalRenderer", suppressSizeToFit: true, width: 120 },
];

export const SALES_AUTOMATION_EMAIL_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'TAXONOMY ID', field: 'taxonomy_id', resizable: true },
    { headerName: 'TAXONOMY NAME', field: 'taxonomy_name', resizable: true },
    { headerName: 'TAXONOMY TYPE', field: 'taxonomy_type', resizable: true },
    { headerName: 'TAXONOMY TYPE NAME', field: 'taxonomy_type_name', resizable: true },
    { headerName: 'EMAIL TEMPLATE ID', field: 'email_template_id', resizable: true },
    { headerName: 'EMAIL TEMPLATE NAME', field: 'email_template_name', resizable: true },
    { headerName: 'EMAIL TEMPLATE SUBJECT', field: 'email_template_subject', resizable: true },
    { headerName: 'ACTIVE', field: 'active', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const CUSTOM_DASHBOARD_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'NAME', field: 'name', resizable: true },
    { headerName: 'ORGANIZATION ID', field: 'organization_id', resizable: true },
    { headerName: 'ORGANIZATION NAME', field: 'organization_name', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'TABLEAU WORKBOOK NAME', field: 'tableau_workbook_name', resizable: true },
    { headerName: 'TABLEAU VIEW NAME', field: 'tableau_view_name', resizable: true },
    { headerName: 'TABLEAU SITE ID', field: 'tableau_site_id', resizable: true },
    { headerName: 'IFRAME WIDTH', field: 'iframe_width', resizable: true },
    { headerName: 'IFRAME HEIGHT', field: 'iframe_height', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'ACTION', sortable: false, resizable: true, cellRenderer: "actionRenderer" },
];

export const UNDISCLOSED_PROXY_LOGS_COLUMN_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'USER', field: 'created_by', resizable: true },
    { headerName: 'START TIME', field: 'start_time', resizable: true },
    { headerName: 'END TIME', field: 'end_time', resizable: true },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'ERROR', field: 'error', resizable: true },
    { headerName: 'CALCULATION DETAILS', sortable: false, cellRenderer: "undisclosedProxyLogModalRenderer", suppressSizeToFit: true, width: 120 },
];

export const CLIENT_DIRECTORY_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'TITLE', field: 'title', resizable: true },
    { headerName: 'TYPE', field: 'type', resizable: true },
    { headerName: 'ORGANIZATION NAME', field: 'organization_name', resizable: true },
    { headerName: 'DESCRIPTION', field: 'description', resizable: true },
    { headerName: 'DEFAULT FILTERS', field: 'default_filter', resizable: true, cellRenderer: "defaultFilterModalRenderer" },
    { headerName: 'BRAND_THEME', field: 'brand_theme', resizable: true, cellRenderer: "brandThemeModalRenderer" },
    { headerName: 'STATUS', field: 'status', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];

export const ENTITY_EMPLOYEE_COUNT_HEADERS = [
    { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
    { headerName: 'ENTITY ID', field: 'entity_id', resizable: true },
    { headerName: 'ENTITY NAME', field: 'entity_name', resizable: true },
    { headerName: 'ENTITY TYPE', field: 'entity_type', resizable: true },
    { headerName: 'MONTH', field: 'month', resizable: true },
    { headerName: 'YEAR', field: 'year', resizable: true },
    { headerName: 'EMPLOYEE COUNT', field: 'employee_count', resizable: true },
    { headerName: 'CREATED BY', field: 'created_by', resizable: true },
    { headerName: 'CREATED AT', field: 'created_at', resizable: true },
    { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 120 },
];
