import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import AsyncSelect from 'react-select/async';

// constants
import { ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant';
import { API_CLIENTS_COLUMN_HEADERS } from '../../utility/constants/tableHeaders';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// components
import Parent from '../Parent';
import SideNav from '../SideNav';
import Home from '../Home';
import ActionRenderer from '../reusable/ActionRenderer';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';
import organizationApi from '../../apis/organizationApi';
import { getUsers } from '../../utility/commonApis';

class ApiClients extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: API_CLIENTS_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: ActionRenderer
            },

            // filters
            organization_id: '',
            role_id: '',
            filters: {
                organization_id: [],
                user_id: []
            },
            filterFields: [
                { 'key': 'organization_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ],

            // options
            organizations: [],
            users: []
        }
        store.sortType = this.props.location.pathname.split('/')[1];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    async componentDidMount() {

        const paramFields = ['_id', 'name', 'client_id', 'organization_id', 'organization_data', 'user_id', 'user_data'];
        store.fields = prepareSelectParam(paramFields);

        this.fetchListData(API_PATH.apiClient, store.fields);

        await this.getOrganizations();
        await this.getUsers();
    }

    getOrganizations = async (option) => {
        organizationApi.getOrganizations(option).then(
            response => {
                const arrTemp = response.map(org => {
                    return { value: org.id, label: org.name };
                });

                this.setState({
                    organizations: arrTemp
                });
            }
        );
    }

    getUsers = async (option) => {
        const arrUsers = await getUsers(option);

        this.setState({
            users: arrUsers
        });
    }

    handleClearFilters = () => {
        this.setState({
            organization_id: '',
            user_id: '',
        }, () => {
            this.handleClearAllFilters(API_PATH.apiClient);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>API Clients</h3>
                            <div className='filter-container'>


                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                            <AsyncSelect
                                                placeholder='Filter by organization'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.organization_id}
                                                defaultOptions={this.state.organizations}
                                                loadOptions={(option) => this.getOrganizations(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'organization_id')}
                                            />
                                        </div>


                                        <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                            <AsyncSelect
                                                placeholder='Filter by user'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.user_id}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => getUsers(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'user_id')}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                />
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}

export default observer(ApiClients);
