import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import store from '../../store'
import { PERMISSION_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, API } from '../../constant'
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'

class Permissions extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: PERMISSION_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            fields: '',
            data: [],
            showrole_id: false,
            showresource_id: false,
            role_id: '',
            role_ids: [],
            role_idStore: [],
            resource_id: '',
            resource_ids: [],
            resource_idStore: [],
            filters: {
                role_id: [],
                resource_id: []
            },
            filterFields: [
                { 'key': 'role_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'resource_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.role) { if (!this.role.contains(e.target)) this.setState({ showrole_id: false }) }
            if (this.resource) { if (!this.resource.contains(e.target)) this.setState({ showresource_id: false }) }
        })

        let paramFields = ['id', 'resource_data', 'role_data', 'created_by_data', 'created_at', 'updated_at'];
        store.fields = prepareSelectParam(paramFields)

        this.fetchListData('permission', store.fields)

        fetch(`${API}/role`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ role_ids: arr, role_idStore: arr })
                }
            })
            .catch(err => {
                console.log(err)
            })

        fetch(`${API}/resource?limit=100`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.resources.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ resource_ids: arr, resource_idStore: arr })
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    componentWillUnmount() {
        document.addEventListener('mousedown', (e) => {
            if (this.role) { if (!this.role.contains(e.target)) this.setState({ showrole_id: false }) }
            if (this.resource) { if (!this.resource.contains(e.target)) this.setState({ showresource_id: false }) }
        })
    }

    handleResourceInputChange(value) {
        this.setState({ resource_id: value })
        if (value.length > 2) {
            let filters = prepareFilterParam([{ 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [value] }])
            fetch(`${API}/resource/?fields=id^,name&filters=${filters}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data.status === 200) {
                        let arr = []
                        data.data.resources.map(item => {
                            arr.push({ value: item.id, label: item.name })
                        })
                        this.setState({ resource_ids: arr, resource_idStore: arr })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>PERMISSION</h3>
                            <div className='filter-container'>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                            <div ref={node => this.role = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Role "
                                                        value={this.state.role_id}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'role_id')}
                                                        onFocus={() => { this.setState({ showrole_id: true }) }} />
                                                    {this.state.showrole_id && this.state.role_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.role_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.role_id.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'role_id')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container' >
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.role_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'role_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('role_id')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showrole_id: false }); this.handleSearchFilters('permission') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showrole_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showrole_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showrole_id: true })} ></i>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.resource = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="By Resource "
                                                        value={this.state.resource_id}
                                                        onChange={(e) => this.handleResourceInputChange(e.target.value)}
                                                        onFocus={() => { this.setState({ showresource_id: true }) }} />
                                                    {this.state.showresource_id && this.state.resource_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.resource_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.resource_id.map((item, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'resource_id')}>
                                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.resource_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'resource_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('resource_id')}>Clear</a> &nbsp; | &nbsp;
                                                                            <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showresource_id: false }); this.handleSearchFilters('permission') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showresource_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showresource_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showresource_id: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleClearAllFilters('permission')}>Clear All</a>
                                        {/*<a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.handleSearchFilters('permission')}>Apply</a>*/}
                                    </div>
                                </div>
                            </div>

                            <Sort />

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div >

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}


export default observer(Permissions)
