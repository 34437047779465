import axios from 'axios';

import store from '../store';
import { ALLOWED_QUERY_OPERATOR, API } from '../constant';
import { prepareFilterParam, prepareSelectParam } from '../utility/queryParams';

const clientDirectoryApi = {

    // get the Client Directory
    getClientDirectory: () => {
        const paramFields = ['id', 'description'];
        const fields = prepareSelectParam(paramFields);

        const url = `${API}/client-directory?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => {
                        if (response.data.status === 200) {
                            resolve(response.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                )
                .catch((err) => reject(err));
        });
    },

    getClientDirectoryTitles: (strName = '') => {
        const arrFields = ['id', 'title'];
        const queryFields = prepareSelectParam(arrFields);

        let queryFilters = "";
        if (strName !== '') {
            queryFilters = prepareFilterParam([
                { key: 'title', value: [strName], op: ALLOWED_QUERY_OPERATOR.substring }
            ]);
        }

        const url = `${API}/client-directory?fields=${queryFields}&filters=${queryFilters}`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => reject(err));
        })
    },

    getClientDirectoryDetails: (clientDirectoryId = 0, fields = []) => {
        const url = `${API}/client-directory/${clientDirectoryId}?fields=${fields}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })
    },

    getClientDirTaxonomy: (client_id) => {
        const queryFields = prepareSelectParam(['id', 'name','description','status','level','parent_id','client_directory_id']);
        const queryFilters = prepareFilterParam([
            { key: 'client_directory_id', value: [client_id], op: ALLOWED_QUERY_OPERATOR.equal }
        ]);

        const url = `${API}/client-directory-taxo?fields=${queryFields}&filters=${queryFilters}`;
        const headers = {
            Authorization: store.token
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(response => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    } else {
                        reject({});
                    }
                }).catch(err => reject(err))
        })

    }

}

export default clientDirectoryApi;
