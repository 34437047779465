import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';


// constants
import { ECOSYSTEM_VERIFICATION_COLUMN_HEADERS, SELECT_STYLES, SELECT_THEME_PRIMARY, ALLOWED_QUERY_OPERATOR, ICE_ENTITY_TYPES, ICE_VERIFICATION_STATUS_LABEL } from '../../constant';

// helpers
import store from '../../store';
import ActionRenderer from '../reusable/ActionRenderer';
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams';

// components
import Parent from '../Parent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import Sort from '../reusable/Sort';
import Pagination from '../reusable/Pagination';

class EcosystemVerification extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: ECOSYSTEM_VERIFICATION_COLUMN_HEADERS,
            context: { componentParent: this },
            components: {
                actionRenderer: ActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            id: '',
            ecosystem_type: '',
            name: '',
            is_verified: '',

            filters: {
                id: [],
                ecosystem_type: [],
                name: [],
                is_verified: []
            },
            filterFields: [
                { 'key': 'id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'ecosystem_type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'name', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'is_verified', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.id) { if (!this.id.contains(e.target)) this.setState({ showIds: false }) }
            if (this.name) { if (!this.name.contains(e.target)) this.setState({ showNames: false }) }
        })

        this.getInitialData();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.id) { if (!this.id.contains(e.target)) this.setState({ showIds: false }) }
            if (this.name) { if (!this.name.contains(e.target)) this.setState({ showNames: false }) }
        })
    }

    getInitialData = () => {
        const paramFields = ['id', 'ecosystem_type', 'name', 'type_data', 'owned_by_data', 'created_at', 'is_verified', 'verified_at', 'verified_by_data'];

        store.fields = prepareSelectParam(paramFields);
        store.currentFilters = prepareFilterParam(this.state.filterFields);

        this.fetchListData('ecosystem', store.fields, store.type);
    }

    handleClearFilters = () => {
        this.setState({
            id: '',
            ecosystem_type: '',
            name: '',
            is_verified: ''
        }, () => this.handleClearAllFilters('ecosystem'));
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Ecosystem Verification</h3>
                            <div className='filter-container'>

                                <div className='filter-row'>
                                    <div ref={node => this.id = node} className="col-md-6 col-sm-6 col-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Search by entity id"
                                                value={this.state.id}
                                                onFocus={() => this.setState({ showIds: true })}
                                                onKeyUp={event => this.handleKeywordEnter(event, 'id')}
                                                onChange={e => this.setState({ id: e.target.value })} />
                                            {this.state.showIds && this.state.filters.id.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.id.map((item, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'id')}>
                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.id.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ id: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by entity type'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.ecosystem_type}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'ecosystem_type')}
                                            options={ICE_ENTITY_TYPES}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ paddingTop: 10 }}>
                                    <div ref={node => this.name = node} className="col-md-6 col-sm-6 col-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Search by entity name"
                                                value={this.state.name}
                                                onFocus={() => this.setState({ showNames: true })}
                                                onKeyUp={event => this.handleKeywordEnter(event, 'name')}
                                                onChange={e => this.setState({ name: e.target.value })} />
                                            {this.state.showNames && this.state.filters.name.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.name.map((item, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'name')}>
                                                                {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.name.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ name: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <Select
                                            placeholder='Filter by verification status'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.is_verified}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'is_verified')}
                                            options={ICE_VERIFICATION_STATUS_LABEL}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClearFilters}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal getInitialData={this.getInitialData}/>

                    </div>
                }
            </div>
        )
    }
}


export default observer(EcosystemVerification)
