import React, { Component } from 'react'
import { toJS, get } from 'mobx';
import store from '../../store'

class TeamActionRenderer extends Component {
    handleEdit = () => {
        store.isPeopleTeamEdit = true;
        store.currentPeopleTeamId = this.props.data.id;
        store.peopleTeamRowIndex = this.props.node.rowIndex;

        this.props.context.componentParent.setState({ peopleTeamError: false });

        let obj = { ...this.props.data };
        Object.keys(this.props.data).map(key => {
            if (key === 'people_id') {
                obj[key] = { 'value': this.props.data.pid, 'label': this.props.data.people_id, email: this.props.data.email }
            }
            else if (key === 'team_role_id') {
                obj[key] = { 'value': this.props.data.trid, 'label': this.props.data.team_role_id }
            }
            else if (key === 'is_primary_contact') {
                obj[key] = { 'value': this.props.data.is_primary_contact, 'label': key}
            } else if (key === 'is_founder') {
                obj[key] = { 'value': this.props.data.is_founder, 'label': this.props.data.is_founder === 1 ? 'Yes' : 'No' }
            }
        })

        store.peopleTeamDetails = obj

        $('#addPeopleTeamModal').modal('show')
    }

    handleDelete = () => {
        store.currentPeopleTeamId = this.props.data.id
        this.props.context.componentParent.handleDeletePeopleTeam(this.props.node.rowIndex)
    }

    render() {
        return (
            <div>
                <a tabIndex='1' className="btn-edit" onClick={this.handleEdit}><i className='fas fa-pencil-alt'></i></a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default TeamActionRenderer

