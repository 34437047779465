import React, { Component } from 'react'
import store from '../../store'

class MentionsActionRenderer extends Component {
    handleDelete = () => {
        store.currentMentionId = this.props.data.id
        this.props.context.componentParent.handleDeleteMentions(this.props.node.rowIndex)
    }

    render() {
        return (
            <div>

                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>

            </div>
        )
    }
}

export default MentionsActionRenderer

