import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
// Components
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Pagination from '../reusable/Pagination'
import TaxonomyModal from '../reusable/TaxonomyModal'
import Home from '../Home'
// Utils
import store from '../../store'
import { INDUSTRY_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams'
import RBAC from '../../utility/rbac'
import { getIndustries } from '../../utility/commonApis'

let isAddAllowed = false

class Industry extends Parent {
    constructor(props) {
        super(props)
        isAddAllowed    = RBAC.hasAccess('industry', 'post', null)

        this.state      = {
            columnDefs: INDUSTRY_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },
            defaultColDef: { suppressMovable: true },

            parent_id: '',
            parent_ids: [],
            parent_idStore: [],
            showindustry: false,

            keyword: '',
            showKeywords: false,
            filters: {
                keyword: [],
                parent_id: [],
            },
            filterFields: [
                { 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'parent_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    async componentDidMount() {
        store.isIndustryList = true;
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.parent_id) { if (!this.parent_id.contains(e.target)) this.setState({ showindustry: false }) }
        })
        let paramFields = ['id', 'name','parent_id']
        store.fields = prepareSelectParam(paramFields)

        //TODO: perform token validator
        this.fetchListData('industry', store.fields)

        // Fetch parent industries
        if (store.parent_industries.length === 0) {
            const filters = prepareFilterParam([
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            ]);
            const parentIndustries = await getIndustries(filters);
            store.parent_industries = parentIndustries;
            this.setState({ parent_ids: parentIndustries, parent_idStore: parentIndustries })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.parent_id) { if (!this.parent_id.contains(e.target)) this.setState({ showindustry: false }) }
        })
    }

    handleAdd = () => {
        store.taxonomyTitle = 'INDUSTRY';
        store.isEdit = false;
        store.isAdd = true;

        $('#taxonomyModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        })
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>
                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>INDUSTRY</h3>

                            <div className='filter-container'>
                                <div className='filter-row' style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                                    <div ref={node => this.word = node} className="col-md-6 col-sm-6 col-xs-6 filter-firstline filter-spacing" style={{ marginRight: 0 }}>
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true, showindustry: false })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6 ml-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div ref={node => this.parent_id = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Select parent industry"
                                                    value={this.state.parent_id}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'parent_id')}
                                                    onFocus={() => { this.setState({ showtype_id: false, showindustry: true }) }} />
                                                {this.state.showindustry && this.state.parent_ids ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.parent_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.parent_id.map((item, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'parent_id')}>
                                                                            {item}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.parent_ids.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'parent_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('parent_id')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showindustry: false }); this.handleSearchFilters('parent_id') }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showindustry ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showindustry: true })} ></i>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isAddAllowed &&
                                <button className="btn-secondary bottom-btn" onClick={this.handleAdd}>ADD INDUSTRY</button>
                            }

                            <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d', marginTop: 20 }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />
                        <TaxonomyModal gridApi={this.gridApi} />
                    </div>
                }

            </div>
        )
    }
}
export default observer(Industry)
