import React, { Component } from 'react';

// helpers
import store from '../../../store';

class SubscriptionAddonRoleActionRenderer extends Component {
    handleDelete = () => {
        const index = this.props.node.rowIndex;
        const subRoleId = this.props.data.id;
        const roleId = this.props.data.role_id ? this.props.data.role_id : '';

        if (subRoleId !== 'NEW') {
            store.apis.push({ path: `subscription-addon-role/${subRoleId}`, method: 'DELETE' });
        }

        store.subscriptionAddonRolesData.splice(index, 1);
        store.subscriptionAddonRoles = store.subscriptionAddonRoles.filter(role => role != roleId);

        this.props.api.setGridOption('rowData',store.subscriptionAddonRolesData);
    }

    render() {
        return (
            <div>
                <a tabIndex='2' className="btn-delete" onClick={this.handleDelete} ><i className='fas fa-trash-alt'></i></a>
            </div>
        )
    }
}

export default SubscriptionAddonRoleActionRenderer;

