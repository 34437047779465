import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import store from '../../../store';
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../../constant';
import FormParent from '../../FormParent';


class AddClientDirTaxonomyModal extends FormParent {
    constructor() {
        super()
        this.state = {
            clientDirectoryTaxonomy: [],

            formFields: {
                taxoId: null,
                name: '',
                description: '',
                status: { value: 1, label: 'Active' },
                level: { value: 0, label: 0 },
                parent_id: { value: null, label: '' },
                has_parent_taxonomy: { value: 0, label: 'No' },
            },

            editParams: [],

            nameEmpty: false,
            has_parent_taxonomyEmpty: false,
            validationFields: ['name', 'level'],

        }

        this.parameters = {
            taxoId: null,
            name: '',
            description: '',
            status: 1,
            level: 0,
            parent_id: null,
            has_parent_taxonomy: ''
        }

    }

    handleAddTaxonomy = () => {
        const generateUniqueId = () => Date.now() + Math.floor(Math.random() * 10000);

        if (!this.handleEmptyFieldValidation()) {
            return;
        }

        const { formFields } = this.state;
        const { clientDirectoryTaxoData, setParentState } = this.props;
        const taxoId = this.parameters?.taxoId || `${generateUniqueId()}-parent_id-${formFields['parent_id']?.value}`;

        const commonData = {
            name: this.parameters?.name,
            description: this.parameters?.description,
            level: store.isEditTaxo ? this.parameters?.level?.value : this.parameters?.level,
            status: store.isEditTaxo ? this.parameters?.status?.value : this.parameters?.status,
            parent_id: store.isEditTaxo ? this.parameters?.parent_id.value : this.parameters?.parent_id,
        }

        const payload = {
            id: this.parameters?.id || 'new',
            taxoId,
            client_directory_id: store?.currentId || null,
            ...commonData
        };

        const formFieldData = {
            parent_name: formFields['parent_id'].label || '-',
            level_name: formFields['level'].label,
            status_name: formFields['status'].label,
            ...commonData
        }

        if (store.isEditTaxo) {
            this.updateExistingTaxo(store, formFields, payload, formFieldData);
        } else {
            this.addNewTaxo(store, formFields, taxoId, payload, clientDirectoryTaxoData, setParentState, formFieldData);
        }

        this.handleClearData();
        $('#addTaxoModal').modal('hide');
    };

    updateExistingTaxo = (store, formFields, payload, formFieldData) => {
        const { clientDirectoryTaxoData } = this.props;
        const index = store.clientDirectoryTaxoRowIndex;

        clientDirectoryTaxoData[index] = {
            ...clientDirectoryTaxoData[index],
            taxoId: formFields['taxoId'],
            ...formFieldData
        };

        if (store.isEdit) {
            if (store.currentTaxoId && payload.id !== 'new') {
                store.apis.push({ path: `client-directory-taxo/${store.currentTaxoId}`, method: 'PATCH', data: payload });
            } else {
                store.clientDirectoryPostCreateTaxo[this.parameters.taxoId] = { path: `client-directory-taxo`, method: 'POST', payload };
            }
        }
    };

    addNewTaxo = (store, formFields, taxoId, payload, clientDirectoryTaxoData, setParentState, formFieldData) => {
        const taxonomyData = {
            id: 'new',
            taxoId,
            ...formFieldData
        };

        if (store.isEdit) {
            const hasUpdateTree = store.apis.some(item => item.isClientDirectory);
            if (!hasUpdateTree) {
                store.apis.push({ path: `client-directory-taxo`, method: false, isClientDirectory: true, currentId: store.currentId });
            }
        }

        store.clientDirectoryPostCreateTaxo[taxoId] = { path: `client-directory-taxo`, method: 'POST', payload };

        const tempClientDirectoryTaxoData = [...clientDirectoryTaxoData, taxonomyData]
        setParentState({ clientDirectoryTaxoData: tempClientDirectoryTaxoData });
        this.props.gridApi.setGridOption('rowData',tempClientDirectoryTaxoData)
    };

    handleOptionChange = (option = {}, strLabel = '') => {

        if (strLabel === 'has_parent_taxonomy' && !option.value) {
            this.handleSelectChange({ value: null, label: null }, 'parent_id')
            this.handleSelectChange({ value: 0, label: 0 }, 'level')
        }


        if (strLabel === 'parent_id' && option.value) {
            this.handleSelectChange(option, 'parent_id')
            this.handleSelectChange(
                { value: Number(option.level) + 1, label: Number(option.level) + 1 }, 'level')
        }

        this.setState({
            [strLabel]: option,
            [`${strLabel}Empty`]: false,
        });
    }


    handleClearData = () => {
        const formFields = {
            taxoId: null,
            name: '',
            description: '',
            status: { value: 1, label: 'Yes' },
            level: { value: 0, label: 0 },
            parent_id: { value: null, label: '' },
            has_parent_taxonomy: { value: 0, label: 'No' },
        }

        this.setState({
            formFields,
            has_parent_taxonomy: { value: false, label: 'No' },
            nameEmpty: false,
            statusEmpty: false,
            parent_idEmpty: false
        })

        this.parameters = {
            taxoId: null,
            name: '',
            description: '',
            status: 1,
            level: 0,
            parent_id: null,
            has_parent_taxonomy: ''
        }

        store.clientDirectoryTaxoDetails = {}
        store.isEditTaxo = false
    }

    render() {

        if (store.isEditTaxo) {
            this.state.formFields = store.clientDirectoryTaxoDetails || this.state.formFields
            this.parameters = store.clientDirectoryTaxoDetails
        }

        const clientDirectoryTaxoData = this.props.clientDirectoryTaxoData.filter((item) => {
            if (item.level < (store.clientDirectoryTaxoDetails.level || 2) && item.id !== store.clientDirectoryTaxoDetails.taxoId) {
                item.label = item.name;
                item.value = item.taxoId || item.id;
                return true
            }
        })

        return (
            <div className="modal fade" id="addTaxoModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{store.isEditTaxo ? 'EDIT TAXONOMY' : 'ADD TAXONOMY'}</h4>
                            <button type="button" className="close" onClick={this.handleClearData} data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Taxonomy name <span className='required'>*</span></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name='name'
                                        value={this.state.formFields.name}
                                        onChange={this.handleInputChange}
                                    />
                                    {this.state.nameEmpty && <div className='error'>Taxonomy name cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Description</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name='description'
                                        value={this.state.formFields.description}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Has Parent Taxonomy <span className='required'>*</span></label>
                                    <Select
                                        name='has_parent_taxonomy'
                                        placeholder='Taxonomy has parent taxonomy? '
                                        styles={this.state.has_parent_taxonomyEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.has_parent_taxonomy}
                                        onChange={(option) => {
                                            this.handleOptionChange(option, 'has_parent_taxonomy');
                                            this.handleSelectChange(option, 'has_parent_taxonomy')
                                        }}

                                        options={[
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' },
                                        ]}
                                    />
                                    {this.state.has_parent_taxonomyEmpty && <div className='error'>Is Parent Taxonomy cannot be empty</div>}
                                </div>
                            </div>

                            {this.state?.formFields?.has_parent_taxonomy?.value > 0 && <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Parent Name <span className='required'>*</span></label>
                                    <Select
                                        name='parent_id'
                                        placeholder='Select a Parent'
                                        styles={this.state.parent_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.parent_id}
                                        onChange={(option) => this.handleOptionChange(option, 'parent_id')}
                                        options={clientDirectoryTaxoData}
                                    />
                                </div>
                            </div>}

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Status <span className='required'>*</span></label>
                                    <Select
                                        name='status'
                                        placeholder='Select a Status'
                                        styles={this.state.statusEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.status}
                                        onChange={(option) => this.handleSelectChange(option, 'status')}
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' },
                                        ]}
                                    />
                                    {this.state.statusEmpty && <div className='error'>Status cannot be empty</div>}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.handleClearData} data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddTaxonomy}> {store.isEditTaxo ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(AddClientDirTaxonomyModal)
