import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import NumberFormat from "react-number-format";
import DatePicker from 'react-datepicker'

// helpers
import store from '../../../store';
import { isObjectEmpty } from '../../../utility/helpers';

// constants
import { ENTITY_TYPES_FOR_ADD_INVESTOR_FUNDING, OPTIONS, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, TAXONOMIES } from '../../../constant';
import { API_PATH } from '../../../utility/constants/apiPath';

// apis
import fundingApi from '../../../apis/fundingApi';
import fundsApi from '../../../apis/fundsApi';
import { getTaxonomyData } from '../../../utility/commonApis';

// components
import FormParent from '../../FormParent';

class InvestorsEditModal extends FormParent {
    constructor() {
        super()
        this.state = {
            companies: [],
            funds: [],
            news: [],
            formFields: {
                entity_id: '',
                entity_type: '',
                invested_amount: '',
                investment_date: '',
                deal_term_id: '',
                is_lead: '',
                funding_breakdown_id: '',
                fund_id: ''
            },
            editParams: [],
            investorError: false,
            validationFields: ['entity_type', 'entity_id', 'deal_term_id', 'is_lead'],
            deal_terms_options: [],
            isApiCalled: false
        }
        this.parameters = {
            entity_id: '',
            entity_type: '',
            invested_amount: '',
            investment_date: '',
            deal_term_id: '',
            is_lead: '',
            funding_breakdown_id: '',
            fund_id: ''
        }
    }

    componentDidMount() {
        getTaxonomyData(TAXONOMIES.dealTerm).then(
            response => {
                this.setState({
                    deal_terms_options: response
                })
            }
        )
    }

    async componentWillReceiveProps() {
        if (!isObjectEmpty(store.investorFundingDetails) && store.investorFundingDetails.investor_funding_id && !this.state.isApiCalled) {
            await this.getInvestorFundingData(store.investorFundingDetails.investor_funding_id);
        } else if (!isObjectEmpty(store.investorFundingDetails)) {
            let objData = {};
            Object.keys(this.state.formFields).map(key => {
                if (key === 'deal_term_id') {
                    const index = this.state.deal_terms_options.findIndex(item => item.value === store.investorFundingDetails.deal_term_id);
                    objData[key] = store.investorFundingDetails.deal_term_id ? this.state.deal_terms_options[index] : '';
                }
                else if (key === 'entity_type') {
                    objData[key] = store.investorFundingDetails.entity_type ? { value: store.investorFundingDetails.entity_type, label: store.investorFundingDetails.entity_type } : '';
                } else if (key === 'is_lead') {
                    objData[key] = store.investorFundingDetails.is_lead !== null ? { value: store.investorFundingDetails.is_lead, label: store.investorFundingDetails.is_lead ? "YES" : "NO" } : '';
                }
                else {
                    objData[key] = store.investorFundingDetails[key] !== null ? store.investorFundingDetails[key] : '';
                }
            });

            this.setState({
                isApiCalled: true,
                formFields: objData
            })
        }

        return true;
    }

    handleLoadFunds = async () => {
        const entityId = this.state.formFields.entity_id.value;
        const entityType = this.state.formFields.entity_type.value;

        fundsApi.getFundsList(entityId, entityType).then(
            response => {
                const arrFunds = response.map(fund => {
                    return {
                        value: fund.id,
                        label: `[${fund.id}] ${fund.name}`
                    }
                });

                this.setState({
                    funds: arrFunds
                });
            }
        );
    }

    getInvestorFundingData = async (fundingBreakdownId) => {
        fundingApi.getInvestorFundingDetails(fundingBreakdownId).then(
            response => {
                let objData = {};

                Object.keys(this.state.formFields).map(key => {
                    if (key === 'entity_id') {
                        objData[key] = !isObjectEmpty(response.entity_data) ? { value: response.entity_data.id, label: response.entity_data.name } : '';
                    } else if (key === 'entity_type') {
                        objData[key] = !isObjectEmpty(response.entity_data) ? { value: response.entity_data.type, label: response.entity_data.type } : '';
                    } else if (key === 'deal_term_id') {
                        objData[key] = !isObjectEmpty(response.deal_term_data) ? { value: response.deal_term_data.id, label: response.deal_term_data.name } : '';
                    } else if (key === 'is_lead') {
                        objData[key] = response.is_lead !== null ? { value: response.is_lead, label: response.is_lead ? "YES" : "NO" } : '';
                    } else if (key === 'investment_date') {
                        objData[key] = response.investment_date !== null ? new Date(response.investment_date) : '';
                    } else if (key === 'fund_id') {
                        objData[key] = !isObjectEmpty(response.fund_data) ? { value: response.fund_data.id, label: response.fund_data.name } : '';
                    } else {
                        objData[key] = response[key] !== null ? response[key] : '';
                    }
                });

                this.setState({
                    isApiCalled: true,
                    formFields: objData
                })
            }
        )
    }

    handleAmountChange = (event) => {
        let targetVal = event.target.value;

        if (targetVal.includes(',')) {
            targetVal = targetVal.replaceAll(',', '')
        }

        const isAmountNumber = /^\d*$/.test(targetVal) || targetVal === '' ? false : true;

        this.parameters.invested_amount = /^\d*$/.test(targetVal) && targetVal !== '' ? targetVal : '';

        this.setState((prevState) => ({
            isAmountNumber,
            formFields: {
                ...prevState.formFields,
                invested_amount: targetVal
            }
        }));
    }

    handleAddInvestor = () => {
        if (this.handleEmptyFieldValidation()) {
            const fbId = store.currentFundingBreakdownId;
            const fundingBreakdownIndex = store.fundingBreakdownsData.findIndex(fb => fb.id == fbId);
            const investorFundingIndex = store.investorFundingRowIndex;
            let objData = Object.fromEntries(Object.entries(this.parameters).filter(([_, item]) => (item != "" || item != [])));

            // add investor funding status
            objData.status = 1;

            if (store.isInvestorFundingEdit) {
                store.investorFundingData[investorFundingIndex].entity_id = this.state.formFields.entity_id.label;
                store.investorFundingData[investorFundingIndex].entity_type = this.state.formFields.entity_type.label;
                store.investorFundingData[investorFundingIndex].deal_term_id = this.state.formFields.deal_term_id.label;
                store.investorFundingData[investorFundingIndex].is_lead = this.state.formFields.is_lead.label;
                store.investorFundingData[investorFundingIndex].amount = this.state.formFields.invested_amount;
                store.investorFundingData[investorFundingIndex].investment_date = this.state.formFields.investment_date;
                store.investorFundingData[investorFundingIndex].fund_id = this.state.formFields.fund_id;

                if (store.isEdit) {
                    if (typeof (fbId) == 'string' && fbId.includes('temp-id')) {
                        const fbAPIIndex = store.apis.findIndex(fbApi => fbApi.tempId == fbId);

                        // update the funding breakdown api request
                        store.apis[fbAPIIndex].data.breakdown[0].investors.push(objData);

                        store.fundingBreakdowns[fundingBreakdownIndex].investors.push(objData);
                    } else if (store.currentInvestorFundingId !== '') {
                        store.apis.push({ path: `${API_PATH.investorFunding}/${store.currentInvestorFundingId}`, method: 'PATCH', data: objData });
                    } else {
                        store.apis.push({ path: `${API_PATH.investorFunding}`, method: 'POST', data: { funding_breakdown_id: fbId, investor_funding: [objData] } });
                    }
                }
                else {
                    store.investorFunding[investorFundingIndex] = objData;
                }
            }
            else {
                store.investorFunding.push(objData);

                const displayData = {
                    name: this.state.formFields.entity_id.label,
                    amount: this.state.formFields.invested_amount
                }

                store.fundingBreakdownsData[fundingBreakdownIndex].investors.push(displayData);

                if (store.isEdit && typeof (fbId) == 'string' && fbId.includes('temp-id')) {
                    const fbAPIIndex = store.apis.findIndex(fbApi => fbApi.tempId == fbId);

                    // update the funding breakdown api request
                    store.apis[fbAPIIndex].data.breakdown[0].investors.push(objData);

                    store.fundingBreakdowns[fundingBreakdownIndex].investors.push(objData);
                } else if (store.isEdit) {
                    store.apis.push({ path: `${API_PATH.investorFunding}`, method: 'POST', data: { funding_breakdown_id: fbId, investor_funding: [objData] } });
                } else {
                    store.fundingBreakdowns[fundingBreakdownIndex].investors.push(objData);
                }
            }

            this.handleClose();
        }
    }

    handleClose = () => {
        store.investorFundingDetails = {};
        store.isInvestorFundingEdit = false;

        this.setState({
            formFields: {
                entity_id: '',
                entity_type: '',
                invested_amount: '',
                investment_date: '',
                deal_term_id: '',
                is_lead: '',
                funding_breakdown_id: ''
            },
            isApiCalled: false
        })

        $('#investorsEditModal').modal('hide');
    }

    render() {
        return (
            <div className="modal fade" id="investorsEditModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{store.isInvestorFundingEdit ? 'EDIT INVESTOR' : 'ADD INVESTOR'}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Entity Type <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select an entity type'
                                        styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.entity_type}
                                        onChange={(option) => this.handleSelectEntityType(option, 'entity_type')}
                                        options={ENTITY_TYPES_FOR_ADD_INVESTOR_FUNDING}
                                    />
                                    {this.state.entity_typeEmpty && <div className='error'>Entity type cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Company Name <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Select a company'
                                        styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        isDisabled={!this.state.formFields.entity_type}
                                        value={this.state.formFields.entity_id}
                                        defaultOptions={this.state.companies}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, this.state.formFields.entity_type.value)}
                                        onChange={(option) => this.handleSelectChange(option, 'entity_id', false)}
                                        onFocus={() => { this.handleLoadCompanies(this.state.formFields.entity_type.value) }}
                                    />
                                    {this.state.entity_idEmpty && <div className='error'>Company cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Fund Name</label>
                                    <AsyncSelect
                                        placeholder='Select a fund'
                                        styles={this.state.fund_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.fund_id}
                                        isDisabled={!this.state.formFields.entity_id}
                                        defaultOptions={this.state.funds}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, API_PATH.fund)}
                                        onChange={(option) => this.handleSelectChange(option, 'fund_id', false)}
                                        onFocus={this.handleLoadFunds}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Amount Invested</label>
                                    <NumberFormat
                                        title='number'
                                        className={this.state.isAmountNumber ? "form-control input-error" : "form-control"}
                                        name='invested_amount'
                                        placeholder='0'
                                        value={this.state.formFields.invested_amount}
                                        onChange={this.handleAmountChange}
                                        thousandSeparator={true}
                                    />
                                    {this.state.isAmountNumber && <div className='error'>Invested amount should be a number</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Investment Date</label>
                                    <DatePicker
                                        name='investment_date'
                                        placeholderText="Select a date"
                                        className='datepicker-form-control'
                                        dateFormat="yyyy/MM/dd"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={new Date()}
                                        selected={this.state.formFields?.investment_date ? new Date(this.state.formFields.investment_date) : null}
                                        onChange={(date) => this.handleDateChange(date, 'investment_date')} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Deal Term <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select a deal term'
                                        styles={this.state.deal_term_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.deal_term_id}
                                        onChange={(option) => this.handleSelectChange(option, 'deal_term_id')}
                                        options={this.state.deal_terms_options}
                                    />
                                    {this.state.deal_term_idEmpty && <div className='error'>Deal term cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Is Lead Investor ? <span className='required'>*</span></label>
                                    <Select
                                        placeholder='Select an option'
                                        styles={this.state.is_leadEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        value={this.state.formFields.is_lead}
                                        onChange={(option) => this.handleSelectChange(option, 'is_lead')}
                                        options={OPTIONS}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.handleAddInvestor()}> {store.isInvestorFundingEdit ? 'Update' : 'Add'}</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(InvestorsEditModal);
