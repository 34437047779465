import React from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';
import store from '../../store';
import { API, SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../constant';
import FormParent from '../FormParent';
import { extPrimaryEmail } from '../../utility/helpers';

class TeamModal extends FormParent {
    constructor() {
        super()
        this.state = {
            users: [],
            roles: [],
            teamRoles: [],
            formFields: {
                user_id: '',
                role_id: '',
                team_role_id: '',
                is_primary: 0,
            },
            validationFields: [],

        }

        this.parameters = {
            user_id: '',
            role_id: '',
            team_role_id: '',
            is_primary: 0,
        }

    }

    componentDidMount() {
        store.teamData = [];
        store.teams = [];

        fetch(`${API}/user?fields=id^,first_name^,last_name^,email_data`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = [];

                    data.data.map(item => {
                        const email = extPrimaryEmail(item.email_data);
                        arr.push({ 
                            value: item.id, 
                            label: `${item.first_name} ${item.last_name} | ${email ? email : 'No email found'}`, 
                            email: email 
                        });
                    });

                    this.setState({ users: arr });
                }
            });

        
        fetch(`${API}/role?fields=id^,name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ roles: arr })
                }
            })

        fetch(`${API}/team-role?fields=id^,name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ teamRoles: arr })
                }
            })
    }

    handleAddTeam = () => {
        if (!this.state.formFields.role_id && !this.state.formFields.team_role_id) {
            this.setState({ team_role_idEmpty: true, role_idEmpty: true })
        }
        else if (!this.state.formFields.role_id) {
            this.setState({ role_idEmpty: true })
        }
        else if (!this.state.formFields.team_role_id) {
            this.setState({ team_role_idEmpty: true })
        }
        else {
            if (this.handleEmptyFieldValidation()) {
                if (store.isTeamEdit) {
                    store.teamData[store.teamRowIndex].uid = this.state.formFields['user_id'].value;
                    store.teamData[store.teamRowIndex].user_id = this.state.formFields['user_id'].label;
                    store.teamData[store.teamRowIndex].email = this.state.formFields['user_id'].email;
                    store.teamData[store.teamRowIndex].role_id = this.state.formFields['role_id'].label;
                    store.teamData[store.teamRowIndex].team_role_id = this.state.formFields['team_role_id'].label;
                    store.teamData[store.teamRowIndex].is_primary = this.state.formFields['is_primary'].value;

                    if (store.isEdit) {
                        if (store.currentTeamId !== '') {
                            store.apis.push({ path: `${store.type}/${store.currentId}/team/${store.currentTeamId}`, method: 'PATCH', multiType: 'team', team: this.parameters })
                        }
                        else {
                            store.apis.push({ path: `${store.type}/${store.currentId}/team`, method: 'POST', multiType: 'team', team: [this.parameters] })
                        }
                    }
                    else {
                        store.teams[store.teamRowIndex] = this.parameters
                    }
                }
                else {
                    store.teams.push(this.parameters)
                    store.teamData.push(
                        {
                            uid: this.state.formFields['user_id'].value,
                            user_id: this.state.formFields['user_id'].label,
                            email: this.state.formFields['user_id'].email,
                            role_id: this.state.formFields['role_id'].label,
                            team_role_id: this.state.formFields['team_role_id'].label,
                            is_primary: this.state.formFields['is_primary'].value
                        }
                    )
                    if (store.isEdit) {
                        store.apis.push({ path: `${store.type}/${store.currentId}/team`, method: 'POST', multiType: 'team', team: [this.parameters] })
                    }
                }
                this.props.gridApi.setGridOption('rowData',store.teamData)
                $('#addTeamModal').modal('hide')
            }
        }
    }

    handleTeamSelectChange(option, str, isMulti) {
        this.state[`${str}Empty`] = false
        this.parameters[str] = option.value
        this.state.formFields[str] = option
        this.setState(this.state)
    }

    render() {
        this.state.formFields = store.teamDetails
        let obj = {}
        Object.keys(store.teamDetails).map(key => {
            obj[key] = store.teamDetails[key].value
        })
        this.parameters = obj

        const isPrimaryValue = () => {
            if (this.state.formFields.is_primary) {
                if (this.state.formFields.is_primary.value === 1) {
                    return true
                } 
            }
            return false
        }

        return (
            <div className="modal fade" id="addTeamModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{store.isTeamEdit ? 'EDIT TEAM' : 'ADD TEAM'}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Team member name{store.isTeamEdit && store.isEdit && <span className='required'>*</span>}</label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        isDisabled={store.isTeamEdit && store.isEdit ? true : false}
                                        styles={this.state.user_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.user_id}
                                        defaultOptions={this.state.users}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                        onChange={(option) => this.handleTeamSelectChange(option, 'user_id', false)}
                                    />
                                    {this.state.user_idEmpty && <div className='error'>Member name cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label>Team member email</label>
                                    <input 
                                        className="form-control" 
                                        type="text"
                                        name='email' 
                                        value={(this.state.formFields.user_id && this.state.formFields.user_id.email) || "-"} 
                                        disabled={true} 
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Role<span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.role_id}
                                        defaultOptions={this.state.roles}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                        onChange={(option) => this.handleTeamSelectChange(option, 'role_id', false)}
                                    />
                                    {this.state.role_idEmpty && <div className='error'>Role cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Team member's role <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please Select'
                                        styles={this.state.team_role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.formFields.team_role_id}
                                        defaultOptions={this.state.teamRoles}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'team_role_id')}
                                        onChange={(option) => this.handleTeamSelectChange(option, 'team_role_id', false)}
                                    />
                                    {this.state.team_role_idEmpty && <div className='error'>Team role cannot be empty</div>}
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">

                                    <div className="form-check">
                                        <input type="checkbox"
                                            className="form-check-input"
                                            id="is_primary"
                                            onChange={(e) => this.handleTeamSelectChange({ value: e.target.checked ? 1 : 0, label: "is_primary" }, 'is_primary', false)}
                                            name="is_primary"
                                            checked={isPrimaryValue()}  />
                                        <label className="form-check-label" htmlFor="is_primary">Primary Contact</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddTeam}> {store.isTeamEdit ? 'Update' : 'Add'}</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(TeamModal)