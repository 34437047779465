import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import {TEAM_COLUMN_HEADERS} from '../../constant'
import store from '../../store'
import TeamModal from '../reusable/TeamModal'
import InviteUserModal from './InviteUserModal'
import FormParent from '../FormParent'
import TeamActionRenderer from '../reusable/TeamActionRenderer'

class Team extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: TEAM_COLUMN_HEADERS,
            context: { componentParent: this },
            components: { actionRenderer: TeamActionRenderer },
            defaultColDef: { suppressMovable: true },
            isInvitePopupOpened: false
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    render() {
        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>TEAM MEMBERS</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddTeamModal}>Add member</button>

                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowInviteUserModal}>Invite member</button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={store.teamData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                components={this.state.components}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <TeamModal gridApi={this.gridApi} />
                <InviteUserModal
                    gridApi={this.gridApi}
                    isEdit={this.props.isEdit}
                    entityType={this.props.entityType}
                />
            </div>
        )
    }
}

export default observer(Team)
