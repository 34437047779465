import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'
// constants
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../constant';
import { API_PATH } from '../../utility/constants/apiPath';

// helpers
import store from '../../store';
import { getMonthArray, getYearArray } from '../../utility/helpers';

// apis
import entityEmployeeCountApi from '../../apis/entityEmployeeCountApi';
import { getEntities } from '../../utility/commonApis';
// components
import FormParent from '../FormParent';
import Home from '../Home';
import SideNav from '../SideNav';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';

class EntityEmployeeCountForm extends FormParent {
    constructor(props) {
        super(props);
        this.state = {

            formFields: {
                employee_count: '',
                entity_id: '',
                entity_type: '',
                month: '',
                year: '',
            },
            validationFields: [
                'employee_count', 'month', 'year', 'entity_id', 'entity_type'
            ],
            editParams: [],

            //options
            entityData: [],
            months: [],
            years: [],
            entityTypes: [
                { value: 'STARTUP', label: 'STARTUP' },
                { value: 'ENABLER', label: 'ENABLER' },
                { value: 'INVESTOR', label: 'INVESTOR' },
                { value: 'CORPORATE', label: 'CORPORATE' },
            ],

            error: false,
            errorMessage: '',

            employee_countEmpty: false,
            entity_idEmpty: false,
            entity_typeEmpty: false,
            monthEmpty: false,
            yearEmpty: false,
        };
        this.parameters = {
            employee_count: '',
            entity_id: '',
            entity_type: '',
            month: '',
            year: '',
        };
    }


    async componentDidMount() {

        const months = getMonthArray();
        const years = getYearArray();

        this.setState({ months, years });

        if (store.isEdit && store.currentId) {
            const paramFields = [
                'id',
                'employee_count',
                'entity_id',
                'entity_type',
                'entity_data',
                'month',
                'year',
            ];

            entityEmployeeCountApi.getEntityEmployeeCountDetails(store.currentId, paramFields).then(
                async response => {
                    await this.transformResponseData(response, months, years);
                }
            );
        }
    }

    transformResponseData = async (response, months, years) => {
        let objTemp = {};

        for (const key of Object.keys(this.state.formFields)) {
            let value = response[key];

            if (key === 'entity_id') {
                const entityData = response?.entity_data || null;
                objTemp[key] = entityData !== null ? { value: entityData.id, label: entityData.name } : '';
            } else if (key === 'month') {
                const monthName = months.find(item => item.value === value);
                objTemp[key] = value !== null ? { value: value, label: monthName.label } : '';
            } else if (key === 'year') {
                const yearName = years.find(item => item.value === value);
                objTemp[key] = value !== null ? { value: value, label: yearName.label } : '';
            } else if (key === 'entity_type') {
                objTemp[key] = value !== null ? { value: value, label: value.toUpperCase() } : '';
            } else {
                objTemp[key] = value !== null ? value : '';
            }
        }

        this.setState({ formFields: objTemp });
    }

    handleAddData = () => {
        this.handleAdd(API_PATH.entityEmployeeCount);
    };

    handleUpdateData = () => {
        this.handleUpdate(API_PATH.entityEmployeeCount);
    };

    getOptionsData = async (entityType, onFocus, option = "", callback) => {

        if (!entityType?.value || (option && option.length < 2 && !onFocus)) {
            if (!entityType?.value) {
                this.setState({
                    entityData: [],
                    entity_typeEmpty: true,
                });
            }
            return;
        }

        let data = await getEntities(entityType.value.toLowerCase(), option);

        this.setState({ entityData: data })

        if (callback) {
            callback(data);
        }
    }

    render() {
        const { months, years } = this.state

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT ENTITY EMPLOYEE COUNT</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD ENTITY EMPLOYEE COUNT</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >ENTITY TYPE <span className='required'>*</span></label>
                                            <Select
                                                name='entity_type'
                                                placeholder='Please select entity Type'
                                                styles={this.state.entity_typeEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_type}
                                                onChange={(option) => this.handleSelectChange(option, 'entity_type')}
                                                options={this.state.entityTypes}
                                            />
                                            {this.state.entity_typeEmpty && <div className='error'>Entity type cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >ENTITY NAME <span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='entity_id'
                                                placeholder='Select Entity'
                                                styles={this.state.entity_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.entity_id}
                                                defaultOptions={this.state.entityData}
                                                loadOptions={(value, callback) => this.getOptionsData(this.state.formFields.entity_type, false, value, callback)}
                                                onChange={(option) => this.handleSelectChange(option, 'entity_id')}
                                                onFocus={() => this.getOptionsData(this.state.formFields.entity_type, true)}
                                            />
                                            {this.state.entity_idEmpty && <div className='error'>Entity cannot be empty</div>}
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >MONTH <span className='required'>*</span></label>
                                            <Select
                                                name='month'
                                                placeholder='Please select month'
                                                styles={this.state.monthEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.month}
                                                onChange={(option) => this.handleSelectChange(option, 'month')}
                                                options={months}
                                            />
                                            {this.state.monthEmpty && <div className='error'>Month cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >YEAR <span className='required'>*</span></label>
                                            <Select
                                                name='year'
                                                placeholder='Please select year'
                                                styles={this.state.yearEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.year}
                                                onChange={(option) => this.handleSelectChange(option, 'year')}
                                                options={years}
                                            />
                                            {this.state.yearEmpty && <div className='error'>Year cannot be empty</div>}
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label >EMPLOYEE COUNT <span className='required'>*</span></label>
                                            <input
                                                className={this.state.employee_countEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                min={1}
                                                name='employee_count'
                                                placeholder="Add Employee Count"
                                                value={this.state.formFields.employee_count}
                                                onChange={this.handleInputChange} />
                                            {this.state.employee_countEmpty && <div className='error'>Employee count cannot be empty</div>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleUpdateData}>SAVE</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={this.handleAddData}>ADD</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>CLEAR</button>
                                    </div>
                                }
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />
                    </div>
                }
            </div>
        );
    }
}
export default observer(EntityEmployeeCountForm);
