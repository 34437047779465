import axios from 'axios';

import { API } from '../constant';

import store from '../store';
import { prepareSelectParam } from '../utility/queryParams';

const startupApi = {

    // get startup linked institutions/entities list
    getLinkedEntities: (startupId) => {
        const fields = prepareSelectParam(['linked_id', 'linked_type', 'linked_data']);

        const url = `${API}/startup/${startupId}/institution?fields=${fields}`;
        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        return new Promise((resolve, reject) => {
            axios.get(url, { headers })
                .then(
                    response => resolve(response.data.data)
                )
                .catch(
                    error => reject(error)
                );
        });
    },

    // accept a join funding request
    acceptJoinFundRequest: (startupId, fundingBreakdownId, invFundingId) => {
        const url = `${API}/startup/${startupId}/funding-breakdown/${fundingBreakdownId}/investor-funding/${invFundingId}/accept-join-request`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        const payload = {
            status: 1
        };

        return new Promise((resolve, reject) => {
            axios.patch(url, payload, { headers })
                .then((response) => {
                    resolve(response.data.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    // reject a join funding request
    rejectJoinFundRequest: (startupId, fundingBreakdownId, invFundingId) => {
        const url = `${API}/startup/${startupId}/funding-breakdown/${fundingBreakdownId}/investor-funding/${invFundingId}/reject-join-request`;

        const headers = {
            'Authorization': store.token,
            'Content-Type': 'application/json'
        };

        const payload = {
            status: 3
        };

        return new Promise((resolve, reject) => {
            axios.patch(url, payload, { headers })
                .then((response) => {
                    resolve(response.data.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

}

export default startupApi;
