import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import ClientDirTaxonomyActionRenderer from './ClientDirTaxonomyActionRenderer';
import cloneDeep from 'lodash/cloneDeep'
import AddClientDirTaxonomyModal from './AddClientDirTaxonomyModal'
import FormParent from '../../FormParent'
import { CLIENT_DIRECTORY_TAXO_COLUMN_HEADERS } from '../../../constant'

class ClientDirTaxonomy extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: CLIENT_DIRECTORY_TAXO_COLUMN_HEADERS,
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ClientDirTaxonomyActionRenderer },
            defaultColDef: { suppressMovable: true },
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    handleShowAddClientDirTaxonomyModal = () => {
        this.setState({ showAddClientDirTaxonomyModal: true },
            () =>
                $('#addTaxoModal').modal({
                    backdrop: 'static',
                    keyboard: true,
                    show: true
                })
        );
    }

    handleDeletedClientDirTaxo = (rowIndex) => {
        if (rowIndex !== null) {
            const clientDirectoryTaxoData = this.props.clientDirectoryTaxoData
            clientDirectoryTaxoData.splice(rowIndex, 1)
            this.props.setParentState({ clientDirectoryTaxoData })
            this.gridApi.setRowData(clientDirectoryTaxoData)
        }
    }

    render() {

        const clientDirectoryTaxoData = cloneDeep(this.props.clientDirectoryTaxoData)?.map((tag) => {
            const parentName = this.props.clientDirectoryTaxoData.find((item) => item.id === tag.parent_id)?.name || '-';
            return {
                id: tag.id,
                taxoId: tag.taxoId || tag.id,
                name: tag.name,
                level: tag.level,
                level_name: tag.level == 0 ? 'Parent' : tag.level == 1 ? 'Child' : 'Grand child',
                has_parent_taxonomy: tag.parent_id && tag.parent_id !== '-' ? { value: true, label: 'Yes' } : { value: false, label: 'No' },
                parent_name: tag.parent_name || parentName,
                parent_id: tag.parent_id && tag.parent_id !== '-' ? { value: tag.parent_id, label: parentName } : { value: null, label: '' },
                description: tag.description || '-',
                status: tag.status ? { value: 1, label: 'Active' } : { value: 0, label: 'Inactive' },
                status_name: tag.status ? 'Active' : 'Inactive',
            }
        })

        return (
            <div>
                <div className='form-container' style={{ marginTop: 10 }}>
                    <div className='form-wrapper'>
                        <h5>TAXONOMY</h5>
                        <div className='funding-addinvestor-btn'>
                            <button className="btn-primary bottom-btn fas fa-plus-circle"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={this.handleShowAddClientDirTaxonomyModal}
                            >
                                {" "}Add Tag
                            </button>
                        </div>

                        <div id='grid' className='ag-theme-balham' style={{ width: '100%', color: '#5d656d' }}>
                            <AgGridReact
                                reactNext={true}
                                defaultColDef={this.state.defaultColDef}
                                columnDefs={this.state.columnDefs}
                                rowData={clientDirectoryTaxoData}
                                domLayout='autoHeight'
                                context={this.state.context}
                                frameworkComponents={this.state.frameworkComponents}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                            />
                        </div>

                    </div>
                </div>

                <AddClientDirTaxonomyModal
                    gridApi={this.gridApi}
                    setParentState={this.props.setParentState}
                    clientDirectoryTaxoData={this.props.clientDirectoryTaxoData}
                />
            </div>
        )
    }
}

export default observer(ClientDirTaxonomy)
