import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import store from '../../store'
import { USER_ROLES_TYPES, API, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Parent from '../Parent'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import Home from '../Home'
import { getUsers } from '../../utility/commonApis'
import TextCellRenderer from '../../utility/Reusable/TextCellRenderer'

class UserRoles extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: [
                { headerName: 'ID', field: 'id', suppressSizeToFit: true, width: 80 },
                {
                    headerName: 'EMAIL',
                    field: 'email',
                    resizable: true,
                    autoHeight: true,
                    cellRenderer: TextCellRenderer
                },
                { headerName: 'ROLE ID', field: 'role_id', resizable: true },
                { headerName: 'ROLE NAME', field: 'role_name', resizable: true },
                { headerName: 'ACCESS TYPE', field: 'access_type', resizable: true },
                { headerName: 'ACTION', sortable: false, cellRenderer: "actionRenderer", suppressSizeToFit: true, width: 100 }
            ],
            context: { componentParent: this },
            components: { actionRenderer: ActionRenderer },

            keyword: '',
            showKeywords: false,
            showrole_id: false,
            role_id: '',
            role_ids: [],
            role_idStore: [],
            users: [],
            user_id: '',
            type: '',
            email: '',
            filters: {
                //keyword: [],
                user_id: [],
                role_id: [],
                type: [],
                email: []
            },
            filterFields: [
                //{ 'key': 'keyword', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'role_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
            ]
        }
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.role_id) { if (!this.role_id.contains(e.target)) this.setState({ showrole_id: false }) }
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
        })
        let paramFields = ['id', 'role_data', 'email_data', 'type']
        store.fields = prepareSelectParam(paramFields)

        this.fetchListData('user-role', store.fields)

        fetch(`${API}/role`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ role_ids: arr, role_idStore: arr })
                }
            })
            .catch(err => {
                console.log(err)
            })

        this.getUsersList()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showKeywords: false }) }
            if (this.role_id) { if (!this.role_id.contains(e.target)) this.setState({ showrole_id: false }) }
            if (this.email) { if (!this.email.contains(e.target)) this.setState({ showemail: false }) }
        })
    }

    getUsersList = async (option = '') => {
        const users = await getUsers(option)

        this.setState({ users })

        return users
    }

    clearFilters = () => {
        this.setState({
            user_id: '',
            type: '',
            email: '',
        })

        this.handleClearAllFilters('user-role')
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>USERS ROLES</h3>
                            <div className='filter-container'>

                                {/*<div className='filter-row'>
                                    <div ref={node => this.word = node} className="col-md-12 col-sm-12 col-xs-12 filter-firstline filter-spacing" >
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node} className='filter-input input-border'
                                                placeholder="Type and Enter to Search"
                                                value={this.state.keyword}
                                                onFocus={() => this.setState({ showKeywords: true })}
                                                onKeyUp={this.handleKeywordEnter}
                                                onChange={e => this.setState({ keyword: e.target.value })} />
                                            {this.state.showKeywords && this.state.filters.keyword.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.keyword.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'keyword')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                        {this.state.keyword.length > 0 &&
                                            <i className="fa fa-times " style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ keyword: '' })
                                                }}>
                                            </i>
                                        }
                                    </div>
                                </div> */}

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline'  >

                                        <div className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                            <AsyncSelect
                                                placeholder='Search by user'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.user_id}
                                                defaultOptions={this.state.users}
                                                loadOptions={(option) => this.getUsersList(option)}
                                                onChange={(option) => this.handleSingleSelectFilter(option, 'user_id')}
                                            />
                                        </div>


                                        <div className="col-md-6 col-sm-6 col-xs-12 " style={{ padding: 0 }}>
                                            <div className='filter-firstline'>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        placeholder='Filter by type'
                                                        styles={SELECT_STYLES}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: SELECT_THEME_PRIMARY
                                                            }
                                                        })}
                                                        value={this.state.type}
                                                        onChange={(option) => this.handleSingleSelectFilter(option, 'type')}
                                                        options={USER_ROLES_TYPES}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline'  >

                                        <div ref={node => this.email = node} className="col-md-6 col-sm-6 col-xs-12 filter-spacing" style={{ padding: 0 }}>
                                            <div className="filter-firstline">
                                                <div>
                                                    <input className='filter-input input-border'
                                                        placeholder="Search by email"
                                                        value={this.state.email}
                                                        onFocus={() => this.setState({ showrole_id: false, showemail: true })}
                                                        onKeyUp={(e) => this.handleInputEnter(e, 'email')}
                                                        onChange={e => this.setState({ email: e.target.value })} />
                                                    {this.state.showemail && this.state.filters.email.length > 0 ?
                                                        <div className='filter-dropdown' style={{ width: '100%' }}>
                                                            <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                                {this.state.filters.email.map((word, i) => {
                                                                    return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'email')}>
                                                                        {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                {this.state.email.length > 0 &&
                                                    <i className="fa fa-times "
                                                        style={{ paddingRight: 10 }}
                                                        onClick={() => {
                                                            this.email.focus()
                                                            this.setState({ email: '' })
                                                        }}>
                                                    </i>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                            <div ref={node => this.role_id = node} className='filter-firstline' >
                                                <div style={{ width: '100%' }}>
                                                    <input className='filter-input'
                                                        style={{ paddingLeft: 15 }}
                                                        placeholder="Filter by Role"
                                                        value={this.state.role_id}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'role_id')}
                                                        onFocus={() => { this.setState({ showrole_id: true, showemail: false }) }} />
                                                    {this.state.showrole_id && this.state.role_ids ?
                                                        <div className='filter-dropdown' >
                                                            <div >
                                                                {this.state.filters.role_id.length > 0 ?
                                                                    <div className='filter-dropdown-wrapper' >
                                                                        {this.state.filters.role_id.map((loc, i) => {
                                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'role_id')}>
                                                                                {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                            </div>
                                                                        })}
                                                                    </div> : null}
                                                            </div>
                                                            <ul className='filter-list-container'>
                                                                <div className='filter-list-wrapper' >
                                                                    <div>
                                                                        {this.state.role_ids.map((item, j) =>
                                                                            <div key={j}>
                                                                                <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'role_id')}>
                                                                                    {item.label}
                                                                                </li><br />
                                                                            </div>)}
                                                                    </div>
                                                                    <div className='filter-stickybtn' >
                                                                        <div style={{ width: 140 }}>
                                                                            <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('role_id')}>Clear</a> | <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showrole_id: false }); this.handleSearchFilters('user-role') }}>Apply</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div> : null}
                                                </div>
                                                {this.state.showrole_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showrole_id: false })} ></i> :
                                                    <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showrole_id: true })} ></i>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={() => this.clearFilters()}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            {/* <Sort />*/}

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(UserRoles)
