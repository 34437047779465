import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select'

import store from '../../store'
import { EXPORT_REQUEST_COLUMN_HEADERS, ALLOWED_QUERY_OPERATOR, SELECT_STYLES, SELECT_THEME_PRIMARY, EXPORT_REQUEST_STATUS } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'

import Home from '../Home'
import Parent from '../Parent'
import SideNav from '../SideNav'
import ActionRenderer from '../reusable/ActionRenderer'
import Modal from '../reusable/Modal'
import Sort from '../reusable/Sort'
import Pagination from '../reusable/Pagination'
import CommentModalActionRenderer from '../reusable/CommentModalActionRenderer'
import CommentModal from '../reusable/CommentModal'

import exportRequestApi from '../../apis/exportRequestApi'


class ExportRequests extends Parent {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: EXPORT_REQUEST_COLUMN_HEADERS,
            rowData: [],
            context: { componentParent: this },
            components: {
                actionRenderer: ActionRenderer,
                CommentModalRenderer: CommentModalActionRenderer
            },
            defaultColDef: { suppressMovable: true },

            user_id: '',
            showuser_id: '',
            user_email: '',
            showuser_email: '',
            status: '',
            show_status: '',
            filters: {
                user_id: [],
                user_email: [],
                status: []
            },
            filterFields: [
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'user_email', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'status', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] }
            ]
        }
        store.sortType = this.props.location.pathname.split('/')[1]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => {
            if (this.user_id) { if (!this.user_id.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.user_email) { if (!this.user_email.contains(e.target)) this.setState({ showuser_email: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
        })

        this.getInitialData()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.user_id) { if (!this.user_id.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.user_email) { if (!this.user_email.contains(e.target)) this.setState({ showuser_email: false }) }
            if (this.status) { if (!this.status.contains(e.target)) this.setState({ showstatus: false }) }
        })
    }

    getInitialData = () => {
        let paramFields = ['id', 'user_data', 'user_email_data', 'subscription_data', 'status', 'comment']
        store.fields = prepareSelectParam(paramFields)

        this.fetchListData('export-request', store.fields)
    }

    saveComment = (requestId, payload) => {
        exportRequestApi.patchRequestData(requestId, payload).then(
            response => {
                if (!isObjectEmpty(response)) {
                    this.getInitialData()
                    $('#commentModal').modal('hide')
                }
            }
        )
    }

    handleClear = () => {
        this.setState({
            status: ""
        }, () => {
            this.handleClearAllFilters('export-request');
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>Export Requests</h3>
                            <div className='filter-container'>
                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div ref={node => this.user_id = node} class="col-md-6 col-sm-6 col-xs-12" style={{ padding: 0 }}>
                                        <div className="filter-firstline">
                                            <div className="w-100">
                                                <input className='filter-input input-border'
                                                    placeholder="Search by User Id"
                                                    value={this.state.user_id}
                                                    onFocus={() => this.setState({ showuser_id: true })}
                                                    onKeyUp={(e) => this.handleInputEnter(e, 'user_id')}
                                                    onChange={e => this.setState({ user_id: e.target.value })} />
                                                {this.state.showuser_id && this.state.filters.user_id.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.user_id.map((word, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'user_id')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            {this.state.user_id.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ user_id: '' })
                                                }}>
                                            </i>
                                            }
                                        </div>
                                    </div>

                                    <div ref={node => this.user_email = node} className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="filter-firstline">
                                            <div className="w-100">
                                                <input className='filter-input input-border'
                                                    placeholder="Search by Email"
                                                    value={this.state.user_email}
                                                    onFocus={() => this.setState({ showuser_email: true })}
                                                    onKeyUp={(e) => this.handleInputEnter(e, 'user_email')}
                                                    onChange={e => this.setState({ user_email: e.target.value })} />
                                                {this.state.showuser_email && this.state.filters.user_email.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.user_email.map((word, i) => {
                                                                return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'user_email')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            {this.state.user_email.length > 0 &&
                                            <i className="fa fa-times "
                                                style={{ paddingRight: 10 }}
                                                onClick={() => {
                                                    this.input.focus()
                                                    this.setState({ user_email: '' })
                                                }}>
                                            </i>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12 p-0">
                                        <Select
                                            placeholder='Please select status'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.status}
                                            onChange={(option) => this.handleSingleSelectFilter(option, 'status')}
                                            options={EXPORT_REQUEST_STATUS}
                                        />
                                    </div>
                                </div>

                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-6 filter-searchbtn" onClick={this.handleClear}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    defaultColDef={this.state.defaultColDef}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    components={this.state.components}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal getInitialData={this.getInitialData}/>
                        <CommentModal handleSaveComment={this.saveComment}/>

                    </div>
                }
            </div>
        )
    }
}

export default observer(ExportRequests)
