import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import store from '../store'

/* Menu operation element not implmeneted for get methods, currently handling only post methods */
let menu = [
    { code: 'startup', name: 'Startups', type: 'startup', route: 'startup', form: 'startupform', operation: null },
    { code: 'investor', name: 'Investors', type: 'investor', route: 'investor', form: 'investorform', operation: null },
    { code: 'corporate', name: 'Corporates', type: 'corporate', route: 'corporate', form: 'corporateform', operation: null },
    { code: 'enabler', name: 'Enablers', type: 'enabler', route: 'enabler', form: 'enablerform', operation: null },
    { code: 'user', name: 'User', type: 'user', route: 'user', form: 'userform', operation: null },
    { code: 'job', name: 'Jobs', type: 'job', route: 'job', form: 'jobform', operation: null },
    { code: 'research', name: 'Research', type: 'research', route: 'research', form: 'researchform', operation: null },
    { code: 'news', name: 'News', type: 'news', route: 'news', form: 'newsform', operation: null },
    { code: 'funding', name: 'Funding Rounds', type: 'funding', route: 'funding', form: 'fundingform', operation: null },
    { code: 'funding', name: 'Funding Verification', type: 'funding-verification', route: 'funding-verification', operation: null },
    { code: 'funding', name: 'Funding Round Requests', type: 'funding-round-requests', route: 'funding-round-requests', operation: null },
    { code: 'banner', name: 'Advertisement', type: 'banner', route: 'banner', form: 'advertform', operation: null },
    { code: 'permission', name: 'Permissions', type: 'permission', route: 'permission', form: 'permissionform', operation: null },
    { code: 'promotion', name: 'Promotions', type: 'promotion', route: 'promotion', form: 'promotionform', operation: null },
    { code: 'role', name: 'Roles', type: 'role', route: 'role', form: 'roleform', operation: null },
    { code: 'resource', name: 'Resources', type: 'resource', route: 'resource', form: 'resourceform', operation: null },
    { code: 'user-role', name: 'UserRoles', type: 'user-role', route: 'user-role', form: 'user-roleform', operation: null },
    { code: 'order', name: 'Offline Purchases', type: 'order', route: 'order/offline', form: 'user-subscriptionform', operation: 'offline' },
    { code: 'subscription', name: 'Subscription', type: 'subscription', route: 'subscription', form: 'subscriptionform', operation: null },
    { code: 'subscription', name: 'Subscription Types', type: 'subscription-types', route: 'subscription-types', form: 'subscriptionTypesform', operation: null },
    { code: 'email-template', name: 'Email Templates', type: 'email-template', route: 'email-template', form: 'email-template', operation: null },
    { code: 'rd-policy', name: 'RD Policy', type: 'rd-policy', route: 'rd-policy', form: 'RDPolicyform', operation: null },
    { code: 'export-request', name: 'Export Requests', type: 'export-request', route: 'export-request', operation: null },
    { code: 'demo-request', name: 'Demo Requests', type: 'demo-request', route: 'demo-request', operation: null },
    { code: 'user', name: 'Deactivate Users', type: 'deactivate-users', route: 'deactivate-users', operation: null },
    { code: 'ecosystem', name: 'Ecosystem Verification', type: 'ecosystem', route: 'ecosystem', operation: null },
    { code: 'undisclosed-proxy', name: 'Undisclosed Proxy', type: 'undisclosed-proxy', route: 'undisclosed-proxy', form: 'undisclosedProxyForm', operation: null },
    { code: 'undisclosed-proxy-log', name: 'Undisclosed Proxy Logs', type: 'undisclosed-proxy-log', route: 'undisclosed-proxy-log', form: null, operation: null },
    { code: 'data-export-policy', name: 'Data Export Policies', type: 'data-export-policy', route: 'data-export-policy', form: 'dataExportPolicyForm', operation: null },
    { code: 'data-export-log', name: 'Data Export Logs', type: 'data-export-log', route: 'data-export-log', form: 'dataExportLogForm',  operation: null },
    { code: 'data-export-product', name: 'Data Export Products', type: 'data-export-product', route: 'data-export-product', form: 'dataExportProductForm',  operation: null },
    { code: 'news-utm', name: 'News UTM Links', type: 'news-utm', route: 'news-utm', form: 'newsUTMLinkForm',  operation: null },
    { code: 'magnitt-department', name: 'MAGNiTT Departments', type: 'magnitt-department', route: 'magnitt-department', form: 'magnittDepartmentForm',  operation: null },
    { code: 'organization', name: 'Organizations', type: 'organization', route: 'organization', form: 'organizationForm',  operation: null },
    { code: 'organization-role', name: 'Organization Roles', type: 'organization-role', route: 'organization-role', form: 'organizationRoleForm',  operation: null },
    { code: 'api-product', name: 'API Products', type: 'api-product', route: 'api-product', form: 'apiProductForm',  operation: null },
    { code: 'api-clients', name: 'API Clients', type: 'api-clients', route: 'api-clients', form: 'apiClientForm',  operation: null },
    { code: 'subscription-addon', name: 'Subscription Addons', type: 'subscription-addon', route: 'subscription-addon', form: 'subscriptionAddonForm',  operation: null },
    { code: 'valuation-calculation', name: 'Valuation Calculation', type: 'valuation-calculation', route: 'valuation-calculation', form: 'valuationCalculationForm',  operation: null },
    { code: 'client-directory', name: 'Client Directory', type: 'client-directory', route: 'client-directory', form: 'clientDirectoryForm',  operation: null },
    { code: 'fund', name: 'Funds', type: 'fund', route: 'fund', form: 'fundsForm',  operation: null },
    { code: 'technology', name: 'Technology', type: 'technology', route: 'technology', form: 'TechnologyForm',  operation: null },
    { code: 'valuation-calculation-log', name: 'Valuation Calculation Logs', type: 'valuation-calculation-log', route: 'valuation-calculation-log', form: null,  operation: null },
    { code: 'people', name: 'People', type: 'people', route: 'people', form: 'peopleform',  operation: null },
    { code: 'custom-dashboard', name: 'Custom Dashboard', type: 'custom-dashboard', route: 'custom-dashboard', form: 'customDashboardForm',  operation: null },
    { code: 'sales-automation-email', name: 'Sales Automation Email', type: 'sales-automation-email', route: 'sales-automation-email', form: 'salesAutomationEmailForm', operation: null },
    { code: 'entity-employee-count', name: 'Entity Employee Count', type: 'entity-employee-count', route: 'entity-employee-count', form: 'entityEmployeeCountForm', operation: null }
]

let taxonomy = [
    { code: 'taxonomy-type', name: 'Taxonomy', type: 'taxonomy-type', route: 'taxonomy-type', operation: null },
    { code: 'location', name: 'Location', type: 'location', route: 'location', form: 'locationForm', operation: null },
    { code: 'industry', name: 'Industry', type: 'industry', route: 'industry', operation: null },
    { code: 'research-taxonomy', name: 'Research Tags', type: 'research-taxonomy', route: 'research-taxonomy', operation: null },
    { code: 'team-role', name: 'Team Roles', type: 'team-role', route: 'team-role', operation: null }
]

class SideNav extends Component {
    constructor(props) {
        super(props)
        menu        = this.hasMenuAccess() // menu.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        taxonomy    = this.hasTaxonomyAccess() // taxonomy.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }

    hasMenuAccess() {
        let tempMenu        = [];
        const arPermissions = store.permissions;

        for (let i = 0; i < menu.length; i++) {
            const code = menu[i].code;
            let isAllowed = false;

            /* As permissions checking for admin panel if get not available then post wont be :) */
            if (!arPermissions[`${code}-get`]) {
                continue;
            } else if (arPermissions[`${code}-get`].length > 0) {
                const arAllowed = arPermissions[`${code}-get`];

                for (let j = 0; j < arAllowed.length; j++) {
                    if (!arAllowed[j].operation) {
                        isAllowed = true;
                        break;
                    }
                }
            }

            // If resource has get access but not list
            if (!isAllowed) {
                continue;
            }

            if (!arPermissions[`${code}-post`] && menu[i].form) {
                delete menu[i].form;
            }
            else if (arPermissions[`${code}-post`] && menu[i].operation != null) {
                const arAllowedPost = arPermissions[`${code}-post`];
                const tempForm = menu[i].form;
                for (let j = 0; j < arAllowedPost.length; j++) {
                    if (menu[i].operation && menu[i].operation == arAllowedPost[j].operation) {
                        menu[i].form = tempForm;
                        break;
                    } else {
                        delete menu[i].form
                    }
                }
            }

            tempMenu.push(menu[i]);
        }

        return tempMenu.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }

    hasTaxonomyAccess() {
        let tempTaxnomy     = [];
        const arPermissions = store.permissions;

        for (let i = 0; i < taxonomy.length; i++) {
            const code = taxonomy[i].code;
            let isAllowed = false;

            /* As permissions checking for admin panel if get not available then post wont be :) */
            if (!arPermissions[`${code}-get`]) {
                continue;
            } else if (arPermissions[`${code}-get`].length > 0) {
                const arAllowed = arPermissions[`${code}-get`];
                for (let j = 0; j < arAllowed.length; j++) {
                    if (!arAllowed[j].operation) {
                        isAllowed = true;
                        break;
                    }
                }
            }

            // If resource has get access but not list
            if (!isAllowed) {
                continue;
            }

            if (!arPermissions[`${code}-post`] && taxonomy[i].form) {
                delete taxonomy[i].form;
            } else if (arPermissions[`${code}-post`] && taxonomy[i].operation != null) {
                const arAllowedPost = arPermissions[`${code}-post`];
                const tempForm = taxonomy[i].form;

                for (let j = 0; j < arAllowedPost.length; j++) {
                    if (taxonomy[i].operation && taxonomy[i].operation == arAllowedPost[j].operation) {
                        taxonomy[i].form = tempForm;
                        break;
                    } else {
                        delete taxonomy[i].form
                    }
                }
            }

            tempTaxnomy.push(taxonomy[i]);
        }

        return tempTaxnomy.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }

    async componentDidMount() {}

    componentDidUpdate() {
        // scroll to selected menu to view
        if (document) {
            const selectedMenu = document.getElementsByClassName('selected-menu')
            if (selectedMenu && selectedMenu.length > 0) {
                selectedMenu[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }

    handleChangeType(obj) {
        store.type = obj.type
        store.route = obj.route
        store.form = ''
        store.page = 0
        store.totalCount = 0
        store.fromCount = 0
        store.toCount = 0
        store.lastPage = 0
        store.taxonomyTitle = ''
        store.currentFilters = ''
        store.currentSort = ''
        store.apis = []
        // this.closeAllModal()
        this.removeBodyOverlay();
    }

    handleChangeForm(obj) {
        store.form = obj.form
        store.type = obj.type
        store.route = obj.route
        store.isSideNav = false
        store.isAdd = true;
        store.isEdit = false
        store.isDeleted = false
        store.currentFilters = ''
        store.currentSort = ''
        store.apis = []
        // this.closeAllModal()
        this.removeBodyOverlay()
    }

    handleShowTaxonomyList() {
        store.type = 'taxonomy'
        store.showTaxonomies = !store.showTaxonomies
    }

    // closeAllModal = () => {
    //     $('#dailogModal').modal('hide')
    //     $('#addTeamModal').modal('hide')
    //     $('#addInvestorModal').modal('hide')
    //     $('#loginModal').modal('hide')
    //     $('#taxonomyModal').modal('hide')
    //     $('#addMentionModal').modal('hide')
    //     $('#exportSuccess').modal('hide')
    //     $('#investorsModal').modal('hide')
    // }

    removeBodyOverlay() {
        const bodyFadeElements = document.getElementsByClassName('modal-backdrop');
        if (bodyFadeElements && bodyFadeElements.length > 0) {
            bodyFadeElements[0].parentNode.removeChild(bodyFadeElements[0]);
        }
    }

    render() {
        return (
            <div>
                {menu.map((item, i) => {
                    return <div key={i} className={`menu-item ${store.route === item.route ? 'selected-menu' : ''}`} >
                        <Link to={`/${item.route}`}
                            className='item'
                            style={store.route === item.route ? { backgroundColor: '#034078', color: '#fff' } : null}
                            onClick={() => this.handleChangeType(item)}>
                            {item.name}
                        </Link>
                        {(item.type !== 'taxonomy' && item.form) &&
                            <Link to={`/${item.route}/add`}
                                className='item-add'
                                style={store.form === item.form ? { backgroundColor: '#034078', color: '#fff' } : null}
                                onClick={() => this.handleChangeForm(item)}>
                                <span className='fas fa-plus-circle' ></span>
                            </Link>}
                    </div>
                })}

                {taxonomy.length > 0 &&
                <div className='taxonomy-menu-item' style={store.type === 'taxonomy' ? { backgroundColor: '#034078', color: '#fff' } : null} >
                    <a className='item'
                        style={store.type === 'taxonomy' ? { color: '#fff' } : null}
                        onClick={() => this.handleShowTaxonomyList()}>taxonomy</a>
                    <i className={store.showTaxonomies ? 'fas fa-chevron-down taxonomy-item-add' : 'fas fa-chevron-right taxonomy-item-add'}
                        onClick={() => store.showTaxonomies = !store.showTaxonomies}></i>
                </div>
                }

                {store.showTaxonomies &&
                    <div className='taxonomy-list'>
                        {taxonomy.map((item, i) => {
                            return (
					            <div key={i} className={`menu-item ${store.route === item.route ? 'selected-menu' : ''}`}>
                                    <Link key={i}
                                        to={`/taxonomy/${item.route}`}
                                        className='item'
                                        style={store.route === item.route ? { backgroundColor: '#034078', color: '#fff' } : null}
                                        onClick={() => this.handleChangeType(item)}>
                                        {item.name}
                                    </Link>
                                    { (item.form) &&
                                        <Link to={`/taxonomy/${item.route}/add`}
                                            className='item-add'
                                            style={store.form === item.form ? { backgroundColor: '#034078', color: '#fff' } : null}
                                            onClick={() => this.handleChangeForm(item)}>
                                            <span className='fas fa-plus-circle' ></span>
                                        </Link>
                                    }
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        )
    }
}

export default observer(SideNav)
