import React from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';

import store from '../../../store';
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY } from '../../../constant';
import { isObjectEmpty } from '../../../utility/helpers';

import FormParent from '../../FormParent';

import roleApi from '../../../apis/roleApi';

class RoleModal extends FormParent {
    constructor() {
        super()
        this.state = {
            roles: [],
            role: '',
            isEmpty: false,
        }
    }

    componentDidMount() {
        roleApi.getRoles().then(
            response => {
                let roles = [];
                
                response.map(role => roles.push({ value: role.id, label: role.name }));

                this.setState({ roles });
            }
        );

    }

    handleAddRole = () => {
        const { role } = this.state;

        if (isObjectEmpty(role)) {
            this.setState({ isEmpty: true });
            return;
        }

        store.rolesData.push({
            id: 'NEW',
            name: role.label,
            role_id: role.value
        });

        store.subscriptionRoles.push(role.value);

        this.props.gridApi.setGridOption('rowData',store.rolesData);
        this.handleClear();
    }

    handleRoleSelect = (option) => {
        this.setState({
            role: option,
            isEmpty: false
        });
    }

    handleClear = () => {
        this.setState({
            role: ''
        });
    }

    render() {
        return (
            <div className="modal fade" id="addRoleModal" >
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">ADD ROLE</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.handleClear}>&times;</button>
                        </div>

                        <div className="modal-body">

                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                    <label >Role Name <span className='required'>*</span></label>
                                    <AsyncSelect
                                        placeholder='Please select a role'
                                        styles={this.state.isEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: SELECT_THEME_PRIMARY
                                            }
                                        })}
                                        cacheOptions
                                        value={this.state.role}
                                        defaultOptions={this.state.roles}
                                        loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                        onChange={this.handleRoleSelect}
                                    />
                                    {this.state.isEmpty && <div className='error'>Role is required.</div>}
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClear}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleAddRole}>Add</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default observer(RoleModal)